/* Order Catalogue Style */
.ordCatg-wrapper .card-bx-sty.box-item-inn {
  margin-right: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.ordCatg-wrapper .card-bx-sty.box-item-inn:last-child {
  margin-right: 0;
}
.ordCatg-dtl-btn h1 {
  color: #f9f9f9 !important;
  font-size: 14px;
  font-weight: 600;
}
.card-bx-sty.box-item-inn.ordCatg-dtl-btn {
  background: #94989e;
  border: 2px solid #979797;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 180px;
  min-width: 150px;
}
.card-bx-sty.table-card-wrap {
  background: #979797;
  padding: 10px;
}
.custom-select-menu.custom-select2
  .custom-select-wrap.small-select-sty
  .react-select__placeholder,
.custom-select-menu .react-select__single-value {
  font-size: 12px;
  color: #6f7276;
}
.custom-select-menu.custom-select2
  .custom-select-wrap.small-select-sty
  .react-select__indicator.react-select__dropdown-indicator {
  border-top-color: #6f7276;
  border-width: 5px;
}
.custom-select-menu.custom-select2
  .custom-select-wrap.small-select-sty
  .react-select__option {
  font-size: 12px;
}
.ordCatg-InnTable {
  margin-top: 20px;
}
.ordCatg-InnTable table thead tr th {
  background: #979797;
  overflow: hidden;
  -webkit-box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a;
  box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  text-align: left;
  vertical-align: middle;
}
.ordCatg-InnTable table tbody:before {
  width: 5px;
  height: 5px;
  content: "";
  display: block;
}
.ordCatg-InnTable table tbody tr td {
  background: #979797;
  padding: 12px 0 12px 10px;
}
.ordCatg-InnTable table tbody tr:first-child td {
  -webkit-box-shadow: inset 0 1.5px 0 #4a4a4a;
  box-shadow: inset 0 1.5px 0 #4a4a4a;
}
.ordCatg-InnTable table tbody tr:last-child td {
  -webkit-box-shadow: inset 0 -1.5px 0 #4a4a4a;
  box-shadow: inset 0 -1.5px 0 #4a4a4a;
}
.ordCatg-InnTable table tbody tr:first-child td:first-child {
  -webkit-box-shadow: inset 1.5px 0 0 #4a4a4a, inset 0 1.5px 0 #4a4a4a;
  box-shadow: inset 1.5px 0 0 #4a4a4a, inset 0 1.5px 0 #4a4a4a;
  border-top-left-radius: 10px;
}
.ordCatg-InnTable table tbody tr:first-child td:last-child {
  -webkit-box-shadow: inset -1.5px 0 0 #4a4a4a, inset 0 1.5px 0 #4a4a4a;
  box-shadow: inset -1.5px 0 0 #4a4a4a, inset 0 1.5px 0 #4a4a4a;
  border-top-right-radius: 10px;
}
.ordCatg-InnTable table tbody tr:last-child td:first-child {
  -webkit-box-shadow: inset 1.5px 0 0 #4a4a4a, inset 0 -1.5px 0 #4a4a4a;
  box-shadow: inset 1.5px 0 0 #4a4a4a, inset 0 -1.5px 0 #4a4a4a;
  border-bottom-left-radius: 10px;
}
.ordCatg-InnTable table tbody tr:last-child td:last-child {
  -webkit-box-shadow: inset -1.5px 0 0 #4a4a4a, inset 0 -1.5px 0 #4a4a4a;
  box-shadow: inset -1.5px 0 0 #4a4a4a, inset 0 -1.5px 0 #4a4a4a;
  border-bottom-right-radius: 10px;
}
.ordCatg-InnTable table tbody tr td:first-child span {
  border-left: 10px solid red;
}
.ordCatg-InnTable table tbody tr td:first-child {
  -webkit-box-shadow: inset 1.5px 0 0 #4a4a4a;
  box-shadow: inset 1.5px 0 0 #4a4a4a;
}
.ordCatg-InnTable table tbody tr td:last-child {
  -webkit-box-shadow: inset -1.5px 0 0 #4a4a4a;
  box-shadow: inset -1.5px 0 0 #4a4a4a;
}
.ordCatg-InnTable table thead tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset 2px 0 0 #4a4a4a;
  box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset 2px 0 0 #4a4a4a;
}
.ordCatg-InnTable table thead tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset -2px 0 0 #4a4a4a;
  box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset -2px 0 0 #4a4a4a;
  width: 110px;
  min-width: 110px;
}
.bg-rounded.white,
.bg-rounded.green {
  background: #fff;
  border: 2px solid #979797;
  -webkit-box-shadow: 0 0 6px 0 #00000061;
  box-shadow: 0 0 6px 0 #00000061;
  border-radius: 5px;
  color: #4a4a4a;
  padding: 5px 10px;
}
.bg-rounded.green {
  background: #c9fb90;
  font-weight: 600;
  padding: 6px 20px;
}
.ordCatg-InnTable table tbody tr td .td-wrap-sty {
  background: #ebeae8;
  border: 1px solid #4a4a4a;
  /* border-radius: 5px; */
  display: block;
  font-size: 12px;
  padding: 4px 5px;
  color: #000;
  text-align: center;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-height: 44px;
  white-space: nowrap;
  cursor: default;
}
.ordCatg-InnTable table thead tr th,
.ordCatg-InnTable table tbody tr td {
  border: none !important;
}
.ordCatg-InnTable table tbody tr td .btn.btn-green-round {
  background: #c9fb90;
  font-weight: 500;
  padding: 7px 30px 4px;
  -webkit-box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.5);
  box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.5);
  border: none;
}
.ordCatg-InnTable table tbody tr td .btn.btn-green-round:hover {
  background: #fff;
}
.ordCatg-InnTable table tbody tr td .whi-round {
  background: #fff;
  border: 1px solid #4a4a4a;
  -webkit-box-shadow: 0 0 4px #000;
  box-shadow: 0 0 4px #000;
  /* border-radius: 5px; */
  display: block;
  height: 100%;
  min-height: 28px;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  padding: 0 10px;
  margin: 0 auto;
}
.td-btn-wrap {
  padding: 1px;
}
.ordCatg-InnTable table thead tr th:nth-child(1),
.ordCatg-InnTable table thead tr th:nth-child(3),
.ordCatg-InnTable table thead tr th:nth-child(4) {
  min-width: 200px;
}
.ordCatg-InnTable table thead tr th:nth-child(2),
.ordCatg-InnTable table thead tr th:nth-child(5),
.ordCatg-InnTable table thead tr th:nth-child(6),
.ordCatg-InnTable table thead tr th:nth-child(7),
.ordCatg-InnTable table thead tr th:nth-child(8),
.ordCatg-InnTable table thead tr th:nth-child(9) {
  width: 105px;
  min-width: 105px;
}
.box-item-inn-wrap::-webkit-scrollbar {
  display: none;
}

/* Store CatgTable */
.ordCatg-InnTable.store-catgTable table tbody tr td:last-child {
  background: #979797;
  padding: 12px 12px 12px 10px;
}
.ordCatg-InnTable.store-catgTable table tbody tr td:first-child span {
  border-left: none;
}
.storecatg-dropMenu {
  max-width: 700px;
  width: 100%;
}
.customCheckBoxLabel {
  display: block;
  position: relative;
  padding-left: 21px;
  margin-bottom: 7px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #250a72;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.customCheckBoxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  border: 1px solid #4a4a4a;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.customCheckBoxLabel input:checked ~ .checkmark {
  background-color: #4a4a4a;
}

/* Supplier RFQ Wrap Style */
.ordCatg-table-wrap.sup-rfq-wrap {
  background: #d3d3d3;
  border: 2px solid #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
}
.sup-rfq-wrap .ordCatg-InnTable table thead tr th,
.ordCatg-InnTable table tbody tr td {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.sup-rfq-wrap .ordCatg-InnTable table tbody:before {
  content: none;
}
.sup-rfq-wrap .ordCatg-InnTable {
  margin-top: 0;
  background: #979797;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  overflow: hidden;
}
.ordCatg-InnTable table tbody tr td:first-child {
  width: 8%;
}
.sup-Oview-list {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  list-style: none;
  padding: 0;
  background: #979797;
  border: 2px solid #4a4a4a;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.sup-Oview-list li {
  padding: 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sup-Oview-list li .btn-sm-rounded {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
}
.supp-Oview-wrap .ordCatg-InnTable table thead tr th {
  background: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.supp-Oview-wrap .ordCatg-InnTable table tbody:before {
  content: none;
}
.supp-Oview-wrap .ordCatg-InnTable table tbody tr td {
  background: transparent;
}
.supp-Oview-wrap .ordCatg-InnTable .table-outWrap {
  background: #979797;
  border: 1px solid #4a4a4a;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
}
.supp-Oview-wrapper .box-items-wrap {
  border: 2px solid #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px;
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-top: -2px;
}
.supp-Oview-wrapper .card-bx-sty {
  border: 2px solid #000;
}
.supp-Oview-wrapper .ordCatg-InnTable table tbody tr td:first-child span {
  border-left: none;
}
.supp-Oview-wrapper .ordCatg-InnTable table tbody tr td {
  background: #979797;
  padding: 12px;
}
.supp-Oview-wrapper .ordCatg-InnTable table tbody tr td:nth-last-of-type(3) {
  text-align: center;
}
.supp-Oview-wrap .ordCatg-InnTable table tbody tr td .whi-round {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 12px;
}
.form-group.cst-checkBx-sty {
  display: inline-block;
}
.cst-checkBx-sty.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.cst-checkBx-sty.form-group label {
  position: relative;
  cursor: pointer;
}
.cst-checkBx-sty.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background: #3c6b04;
  border: 1px solid #4a4a4a;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}
.cst-checkBx-sty.form-group input:checked + label:before {
  background: #c8061d;
}
.ordCatg-InnTable table tbody tr td:first-child {
  width: auto;
}
.ordCatg-InnTable table thead tr th:nth-child(2),
.ordCatg-InnTable table thead tr th:nth-child(5),
.ordCatg-InnTable table thead tr th:nth-child(6),
.ordCatg-InnTable table thead tr th:nth-child(7),
.ordCatg-InnTable table thead tr th:nth-child(8),
.ordCatg-InnTable table thead tr th:nth-child(9) {
  width: auto;
  min-width: auto;
}
.ordCatg-InnTable table thead tr th:nth-child(1),
.ordCatg-InnTable table thead tr th:nth-child(3),
.ordCatg-InnTable table thead tr th:nth-child(4),
.ordCatg-InnTable table thead tr th:last-child {
  min-width: auto;
}
.ordCatg-InnTable table tbody tr td {
  /* min-width: 80px; */
  max-width: 280px;
}

.ordCatg-table-wrap.sup-rfq-wrap {
  width: 100%;
}
.ordCatg-table-wrap.sup-rfq-wrap.one-of-two {
  width: 49.87%;
}
.ordCatg-table-wrap.sup-rfq-wrap.one-of-three {
  width: 33%;
}
.ordCatg-table-wrap.sup-rfq-wrap .table-title {
  color: #000;
  font-size: 16px !important;
}
@media only screen and (max-width: 1600px) {
  .ordCatg-dtl-btn h1 {
    font-size: 11px;
  }
  .card-bx-sty.box-item-inn.ordCatg-dtl-btn .btn.btn-yellow {
    padding: 4px 15px;
    font-size: 12px;
  }
  .ordCatg-InnTable table thead tr th,
  .ordCatg-InnTable table tbody tr td .whi-round {
    font-size: 12px;
  }
  .bg-rounded.white,
  .bg-rounded.green {
    padding: 3px 10px;
  }
  .ordCatg-InnTable table tbody tr td .td-wrap-sty {
    font-size: 10px;
    max-height: 37px;
  }
  .ordCatg-InnTable table tbody tr td .btn.btn-green-round {
    font-size: 12px;
    padding: 4px 30px 3px;
  }
  .ordCatg-InnTable table tbody tr td .td-wrap-sty,
  .ordCatg-InnTable table tbody tr td .whi-round {
    min-height: 23px;
  }
  .ordCatg-InnTable table tbody tr td {
    /* padding: 5px 10px !important; */
    padding: 0 0 8px !important;
  }
  .ordCatg-InnTable table thead tr th {
    padding: 16px 10px;
  }
  .ordCatg-InnTable table tbody tr td .whi-round {
    width: 80px;
    padding: 3px 10px;
    line-height: normal;
  }
}
@media only screen and (max-width: 992px) {
  .pageWrapper {
    padding: 20px 10px;
  }
  .pageContainer {
    padding: 15px;
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 768px) {
  .storecatg-dropMenu {
    width: 100%;
  }
  .sup-Oview-list {
    font-size: 16px;
  }
  .sup-Oview-list li .btn-sm-rounded {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .ordCatg-InnTable table tbody tr td .td-wrap-sty {
    max-width: 220px !important;
  }
}
@media only screen and (max-width: 576px) {
  .ordCatg-table-wrap .custom-select-menu.text-bx-wrap {
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
  .card-bx-sty.box-item-inn h1 {
    font-size: 12px;
  }
  .card-bx-sty.box-item-inn.ordCatg-dtl-btn {
    min-width: 110px;
    padding: 6px 10px;
  }
  .card-bx-sty.box-item-inn.ordCatg-dtl-btn .btn.btn-yellow {
    padding: 4px 11px 2px;
    font-size: 12px;
  }
  .box-item-inn-wrap {
    width: 100%;
    margin-bottom: 10px;
  }
  .pageContainer {
    padding: 30px 15px !important;
  }
}
