@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-ExtraLight.eot");
  src: url("assets/fonts/Montserrat-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-ExtraLight.woff2") format("woff2"),
    url("assets/fonts/Montserrat-ExtraLight.woff") format("woff"),
    url("assets/fonts/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-SemiBold.eot");
  src: url("assets/fonts/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.eot");
  src: url("assets/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Bold.eot");
  src: url("assets/fonts/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Medium.eot");
  src: url("assets/fonts/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Medium.woff") format("woff"),
    url("assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Black.eot");
  src: url("assets/fonts/Montserrat-Black.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-Black.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Black.woff") format("woff"),
    url("assets/fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Light.eot");
  src: url("assets/fonts/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-Light.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Light.woff") format("woff"),
    url("assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-ExtraBold.eot");
  src: url("assets/fonts/Montserrat-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-ExtraBold.woff") format("woff"),
    url("assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Thin.eot");
  src: url("assets/fonts/Montserrat-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Montserrat-Thin.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Thin.woff") format("woff"),
    url("assets/fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  min-width: 320px;
  margin: 0;
  font-family: "Montserrat";
  font-weight: normal;
  -webkit-font-smoothing: antialised;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}
/*Generic Classes*/
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
button {
  outline: none !important;
}
.btn {
  font-family: "Montserrat" !important;
}
/* .btn.focus, .btn:focus {outline: 0;box-shadow: none !important;} */
.bg-dark-green {
  background: #3c6b04 !important;
}
.bg-white {
  background: #fff !important;
}
.bg-green {
  background: #569c05 !important;
}
.bg-olive {
  background: #a1ba07 !important;
}
.bg-dark-blue {
  background: #250a72 !important;
}
.bg-light-grey {
  background: #ebebeb !important;
}
.bg-dark-yellow {
  background: #e6a935 !important;
}
.bg-light-orng {
  background: #d5c00b !important;
}
.bg-dark-pink {
  background: #e96e4c !important;
}
.bg-sky-blue {
  background: #206f9e !important;
}
.bg-dark-red {
  background: #c8061d !important;
}
.bg-dark-brown {
  background: #730310 !important;
}
.bg-dark-grey {
  background: #1e4356 !important;
}
.bg-grey {
  background: #757d6c !important;
}
.dark-green-clr {
  color: #3c6b04 !important;
}
.green-clr {
  color: #569c05 !important;
}
.value-in {
  opacity: 1;
}
.dark-blue-clr {
  color: #250a72 !important;
}
.dark-brown-clr {
  color: #730310 !important;
}
.dark-yellow-clr {
  color: #e6a935 !important;
}
.dark-pink-clr {
  color: #e96e4c !important;
}
.dark-red-clr {
  color: #c8061d !important;
}
.sky-blue-clr {
  color: #206f9e !important;
}
.light-orng-clr {
  color: #ffc107 !important;
}
.white-clr {
  color: #fff !important;
}
.text-blue {
  color: #0399ac !important;
}
.dark-clr {
  color: #000 !important;
}
.fz-14 {
  font-size: 14px !important;
}
.fz-16 {
  font-size: 16px !important;
}
.fz-28 {
  font-size: 28px !important;
}
.min-H100 {
  min-height: 100% !important;
}
.tb-buttons-wrap {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  margin-right: 0;
}
.btn-disable-wrap {
  cursor: not-allowed !important;
}
.btn-disable-wrap .btn {
  pointer-events: none !important;
}
.btn-disable {
  opacity: 1 !important;
}
.btn-disable:hover,
.btn-disable:focus {
  cursor: not-allowed !important;
}
.lineheight-normal {
  line-height: normal !important;
}
input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.table {
  margin-bottom: 0;
}
.btn {
  padding: 13px 15px 10px;
  -o-transition: all ease-in-out 0.2s;
  -webkit-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  font-family: "montserratregular", Arial, Helvetica, sans-serif;
  text-decoration: none;
}
.btn-primary {
  border: 1px solid #fff;
  color: #fff;
  background: #250a72;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #fff !important;
  background: #fff !important;
  color: #250a72 !important;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-secondary {
  background: #250a72;
  border: 1px solid #250a72;
  color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
  border-color: #250a72 !important;
  color: #250a72 !important;
  background: #fff !important;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cur-poi {
  cursor: pointer;
}
.btn-disable {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}
.not-allowed {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.no-shadow {
  box-shadow: none !important;
}
.title-dark-20 {
  font-weight: 600;
  font-size: 20px;
  color: #250a72;
  margin: 0;
}
.bg_orng {
  background-color: #e6a935 !important;
}
.black-border {
  border-color: #000 !important;
}
.btn-sty-title {
  background: #ebebeb;
  border: 1px solid #000;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 36px;
  /* color: #250a72; */
  color: #000;
  padding: 7px;
  text-align: center;
}
.btn.btn-yellow {
  background: #e6a935;
  border: 1px solid #979797;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  padding: 6px 15px;
  font-size: 14px;
  color: #f9f9f9;
  font-weight: 600;
}
.btn.btn-yellow:hover {
  color: #e6a935;
  background: #fff;
}
.btn.btn-red {
  background: #c8061d;
  border: 1px solid #979797;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  padding: 6px 15px;
  font-size: 14px;
  color: #f9f9f9;
  font-weight: 600;
}
.btn.btn-red:hover {
  color: #c8061d;
  background: #fff;
}
.text-white {
  color: #fff !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.text-black {
  color: #000 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background: transparent !important;
}
input {
  filter: none !important;
  -webkit-filter: none !important;
}
.table-title {
  color: #0399ac;
  font-size: 14px !important;
}
.border-x {
  border-left: 1px solid #0399ac !important;
  border-right: 1px solid #0399ac !important;
}
/* Theme Button Blue */
.btn.btn-theme-blue {
  background: #250a72;
  border-radius: 4px;
  -webkit-filter: drop-shadow(3px 3px 4px #4a4a4a);
  filter: drop-shadow(3px 3px 4px #4a4a4a);
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  max-width: 200px;
  width: 100%;
  margin: 5px;
  border: 1.5px solid #250a72;
}
.btn.btn-theme-blue:hover {
  background: #fff;
  color: #250a72;
}
.btn-sm-rounded {
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 4px 15px;
}
/* Back Theme Button */
.pageWrapper .pageGoBackBtn {
  min-width: 160px;
  margin-bottom: 30px;
  background: #250a72;
  border: 2px solid #250a72;
  border-radius: 18px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 7px 20px;
  border: 1px solid #250a72;
  -o-transition: all ease-in-out 0.2s;
  -webkit-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.pageWrapper .pageGoBackBtn:hover,
.pageWrapper .pageGoBackBtn:focus {
  border-color: #250a72;
  background: #fff;
  color: #250a72;
}
/* Theme Polygon Buttons */
.btn-polygon-sty {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 97px;
  height: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  color: #fff;
  line-height: 11px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn-polygon-sty:hover {
  color: #fff;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.btn-polygon-sty img {
  width: 25px;
  height: 25px;
  margin-bottom: 2px;
}
.btn-polygon-sty.red img {
  width: 19px;
  height: 19px;
  margin-bottom: 5px;
}
.btn-polygon-sty.green {
  background-image: url(./assets/images/polygon-green.svg);
}
.btn-polygon-sty.blue {
  background-image: url(./assets/images/Polygon-blue.svg);
}
.btn-polygon-sty.orange {
  background-image: url(./assets/images/Polygon-orange.svg);
}
.btn-polygon-sty.dark-green {
  background-image: url(./assets/images/Polygon-darkGreen.svg);
}
.btn-polygon-sty.dark-blue {
  background-image: url(./assets/images/Polygon-darkBlue.svg);
}
.btn-polygon-sty.dark-pink {
  background-image: url(./assets/images/Polygon-darkPink.svg);
}
.btn-polygon-sty.red {
  background-image: url(./assets/images/Polygon-red.svg);
}
.btn-polygon-sty.grey {
  background-image: url(./assets/images/Polygon-grey.svg);
}
.themeReguler-btn {
  background: #fff;
  border: none;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 200px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  font-weight: 500;
  font-size: 16px;
  color: #f9f9f9;
  position: relative;
  z-index: 0;
}
.themeReguler-btn:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 0;
  background: #fff;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  z-index: -1;
  opacity: 0;
  border-radius: 5px;
}
.themeReguler-btn:hover:before,
.themeReguler-btn:focus:before {
  height: calc(100% - 4px);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  opacity: 1;
}
.themeReguler-btn:hover,
.themeReguler-btn:focus,
.themeReguler-btn:active {
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #212529;
}
/* .form-btn .themeReguler-btn.bg-sky-blue {z-index: auto;border: 2px solid #206F9E;}
.form-btn .themeReguler-btn.bg-sky-blue:hover {background-color: #fff !important;} */
/* Custom Select Style */
.drop-menu-select {
  padding: 0;
  max-width: 0;
  list-style: none;
}
.custom-select-menu .react-select__control {
  background: transparent;
  border: none;
  padding: 0 10px;
  min-height: auto;
  border-radius: 0;
}
.custom-select-menu .react-select__placeholder,
.custom-select-menu .react-select__single-value {
  font-size: 16px;
  color: #f9f9f9;
}
.custom-select-menu.text-bx-wrap {
  padding: 6.5px 0;
  min-width: 120px;
  width: 100%;
  max-width: 215px;
}
.custom-select-menu .react-select__value-container {
  padding: 0;
  min-width: auto;
  line-height: normal;
}
.custom-select-menu .react-select__indicator-separator {
  display: none;
}
.custom-select-menu
  .react-select__indicator.react-select__dropdown-indicator
  svg {
  display: none;
}
.custom-select-menu .react-select__indicator.react-select__dropdown-indicator {
  border: 7px solid transparent;
  border-top-color: #fff;
  margin-top: 8px;
  padding: 0;
}
.custom-select-menu .react-select__input input,
.custom-select-wrap,
.custom-select-menu .react-select__control.react-select__control--menu-is-open,
.custom-select-menu .react-select__control {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.custom-select-menu .react-select__menu {
  background: #250a72;
  border-radius: 4px;
  margin-top: 10px;
  width: calc(100% + 4px);
  margin-left: -2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* .custom-select-menu .react-select__menu:after{border:7px solid transparent;border-bottom-color:#250a72;position:absolute;content:'';right:1px;top:-13px} */

.custom-select-menu
  .react-select__option.react-select__option--is-focused.active,
.custom-select-menu .react-select__option--is-selected {
  background-color: #569c05 !important;
}
.custom-select-menu .react-select__option.react-select__option--is-focused {
  background-color: #f41d37;
}
/* Custom Select Style2 */
.label-bold {
  font-weight: bold;
  font-size: 18px;
  color: #250a72;
  margin: 0;
}
.custom-select2.text-bx-wrap {
  background: #fff;
  border: 2px solid #9b9b9b;
  border-radius: 4px;
}
.custom-select2.text-bx-wrap.input-type-select {
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #979797;
  z-index: auto;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__control {
  padding: 0;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__placeholder,
.custom-select-menu .react-select__single-value {
  font-size: 16px;
  color: #00000080;
  font-weight: 600;
  cursor: pointer;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__menu,
.custom-select2.text-bx-wrap.input-type-select
  .pagin-select-menu
  .dropdown-menu.filter-drop {
  background: #cccbcb;
}
.custom-select2.text-bx-wrap.input-type-select
  .react-select__option.react-select__option--is-focused.active,
.custom-select2.text-bx-wrap.input-type-select
  .react-select__option.react-select__option--is-focused,
.custom-select2.text-bx-wrap.input-type-select
  .react-select__option--is-selected,
.custom-select2.text-bx-wrap.input-type-select
  .pagin-select-menu
  .dropdown-menu.filter-drop
  li:hover {
  background-color: #979797;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__option,
.custom-select2.text-bx-wrap.input-type-select
  .pagin-select-menu
  .dropdown-menu.filter-drop
  li {
  color: #292828;
  font-size: 14px;
  font-weight: 600;
}
.custom-select2.text-bx-wrap.input-type-select
  .react-select__indicator.react-select__dropdown-indicator {
  border-top-color: #00000080;
}
.custom-select2.text-bx-wrap.input-type-select
  .pagin-select-menu
  .dropdown-toggle {
  padding: 4px 0 4px;
  font-size: 16px;
  color: #00000080;
  font-weight: 500; /* min-height: 40px; */
}
.custom-select2.text-bx-wrap.input-type-select
  .pagin-select-menu
  .dropdown-menu.filter-drop
  .filter-search-wrap
  input {
  border-color: #979797;
}
.custom-select2.text-bx-wrap.input-type-select
  .pagin-select-menu
  .dropdown-menu.filter-drop {
  margin-top: 4px !important;
}
.custom-select-menu.custom-select2 .react-select__placeholder,
.custom-select-menu .react-select__single-value {
  font-size: 14px;
  color: #250a72;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__placeholder,
.custom-select2.text-bx-wrap.input-type-select .react-select__single-value {
  color: #495057;
}
.custom-select-menu.custom-select2
  .react-select__indicator.react-select__dropdown-indicator {
  border-top-color: #250a72;
  border-width: 6px;
}
.custom-select-menu.custom-select2 .react-select__menu-notice--no-options {
  color: #fff;
  opacity: 1;
}
.no-bg {
  background: transparent !important;
}
.custom-select2.text-bx-wrap.orng-select {
  background: #e6a935;
  border: 2px solid #000000;
}
.custom-select2.text-bx-wrap.orng-select .react-select__placeholder,
.custom-select2.text-bx-wrap.orng-select .react-select__single-value {
  font-size: 14px;
  color: #000000;
}
.custom-select2.text-bx-wrap.orng-select .react-select__menu {
  background: #e6a935;
}
.custom-select2.text-bx-wrap.orng-select .react-select__option {
  color: #000000;
}
.custom-select2.text-bx-wrap.orng-select
  .react-select__option.react-select__option--is-focused,
.custom-select2.text-bx-wrap.orng-select .react-select__option--is-selected {
  background-color: #ffffff;
}
.custom-select2.text-bx-wrap.orng-select .react-select__menu:after {
  border-bottom-color: #e6a935;
}
.custom-select2.text-bx-wrap.orng-select
  .react-select__indicator.react-select__dropdown-indicator {
  border-top-color: #000000;
}
.custom-select2.text-bx-wrap.orng-select
  .react-select__menu-notice--no-options {
  color: #000000;
  opacity: 1;
}

/* Header Styles */
.pageWrapper {
  height: 100vh;
  background: url(./assets/images/login-bg.jpg);
  background-size: cover;
  background-position: center center;
  padding: 50px 12px;
  overflow-y: auto;
}
.pageContainer {
  margin: 0 0 50px;
  background: rgba(249, 249, 249, 0.7);
  border: 1.5px solid #979797;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px;
}
.pageHeader {
  margin: 0 0 5px;
}
.logoHolder img {
  width: 210px;
}
.pageHeader > div {
  padding: 0 15px;
}
.pageHeader h1 {
  text-transform: uppercase;
}
.pageHeader .pageHeading {
  padding: 6px 15px;
  background: #ebebeb;
  border: 1px solid #000000;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
/* Card Box Inner Style */
.card-bx-sty.box-item-inn {
  background: #ebeae8;
  text-align: center;
  padding: 8px 20px;
}
.card-bx-sty.box-item-inn h1 {
  font-weight: 600;
  font-size: 14px;
  color: #220022;
  margin-bottom: 4px;
}
.card-bx-sty.box-item-inn p {
  font-weight: normal;
  font-size: 13px;
  color: #000;
  margin: 0;
}

/* Theme Modal Style */
.theme-Roundedmodal .modal-content {
  background: transparent;
  border: none;
  border-radius: 0;
}
.theme-Roundedmodal .modal-content .modal-header {
  border: none;
}
.theme-Roundedmodal .modal-content .modal-card {
  background: #0399ac;
  border: 2px solid #028797;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 8px 10px;
  margin-bottom: 5px;
}
.theme-Roundedmodal .modal-content .modal-card label {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  margin: 0;
  margin-right: 50px;
}
.theme-Roundedmodal .modal-content .modal-body {
  padding: 10px;
  background: #ebeae8;
  border: 2px solid #979797;
  border-radius: 10px;
}
.theme-Roundedmodal .modal-content .custom-select2.text-bx-wrap {
  background: transparent;
  border: none;
  border-radius: 4px;
}
.theme-Roundedmodal
  .modal-content
  .custom-select-menu.custom-select2
  .custom-select-wrap.small-select-sty
  .react-select__placeholder,
.theme-Roundedmodal
  .modal-content
  .custom-select-menu
  .react-select__single-value {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 400;
}
.theme-Roundedmodal .modal-card .custom-select-menu .react-select__control {
  padding-right: 0;
  padding-left: 0;
}
.theme-Roundedmodal
  .modal-card
  .custom-select-menu.custom-select2
  .custom-select-wrap.small-select-sty
  .react-select__indicator.react-select__dropdown-indicator {
  border-top-color: #4a4a4a;
  border-width: 6px;
}
.modalCard-text {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
}
.modalCard-text.font-weight-bold {
  font-size: 14px;
}
.modalCard-value {
  margin-left: 10px;
}
.modalTable-wrap .table thead th {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a4a;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #ebeae8;
  border: unset;
  box-shadow: 2px 0px 0px 2px #dee2e6;
}
.modalTable-wrap.table-responsive {
  max-height: 365px;
}

.modalTable-wrap .table tbody td {
  font-weight: 500;
  font-size: 13px;
  color: #1a1a1a;
  border: none;
  padding: 4px 12px;
}
.modalTable-wrap .table tbody td:nth-child(2) {
  text-align: center;
}
.modalTable-wrap .table thead th:nth-child(2),
.modalTable-wrap .table thead th:nth-child(3) {
  width: 100px;
  min-width: 100px;
  text-align: center;
}
.rounded-td-wrap {
  background: #ddffd1;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 12px;
  color: #000;
  width: 100%;
  display: inline-block;
  text-align: right;
}
.model-footer.custom-footer {
  background: #c9fb90;
  border: 2px solid #979797;
  border-radius: 10px;
  padding: 10px;
  margin-top: 2px;
}
.footer-close-btn.close {
  border: 2px solid #f4a40b;
  background: #e6a935;
  opacity: 1;
  padding: 7px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  text-shadow: none;
  margin-top: 10px;
}
.modal-btn-wrap {
  padding-top: 10px;
  border-top: 1px solid #d9d9d9;
  margin-top: 10px;
}
.modal-btn-wrap .footer-close-btn.close {
  float: none;
  margin: 0;
  padding: 9px 15px;
  font-size: 16px;
}
.footer-close-btn.close:hover {
  background: transparent;
  color: #e19504;
  opacity: 1 !important;
}
.theme-Roundedmodal .model-footer .bg-green {
  background: #85c33d !important;
}
.nutr-items-wrap ul {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
}
.nutr-items-wrap {
  width: 33.33%;
  padding-left: 10px;
  padding-right: 10px;
}
.nutrTable-wrap {
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
}
.nutrTable-wrap .nutr-items-wrap:first-child .nutr-indicator-wrap li {
  text-align: left;
}
.nutr-dtls-info.nutr-type {
  padding: 6px 15px;
  font-weight: 600;
  font-size: 14px;
}
.nutri-head-wrap {
  background: #cddc39 !important;
  border: 2px solid #bac92d !important;
}
.nutri-head-wrap .modalCard-text {
  color: #212529 !important;
  font-weight: 600 !important;
}
.nutr-dtls-info {
  background: #cddc39;
  border: 2px solid #bac92d;
  border-radius: 5px;
  padding: 12px 15px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.nutr-dtls-info li {
  padding: 3px 0;
}
.nutr-indicator-wrap,
.nutr-order-wrap {
  padding-left: 0;
  margin-left: 20px;
}
.nutr-indicator-wrap li,
.nutr-order-wrap li {
  background: #cddc39;
  border: 2px solid #bac92d;
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: right;
  font-weight: 500;
  min-height: 31px;
}
.nutr-indicator-wrap li:last-child,
.nutr-order-wrap li:last-child {
  margin-bottom: 0;
}
.nutr-indicator-wrap li:first-child,
.nutr-order-wrap li:first-child {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600 !important;
  text-align: center;
}
.nutr-order-wrap li {
  background: #cddc39;
}
.theme-Roundedmodal .modal-header .close {
  padding: 9px;
  margin: -1rem -1rem -1rem auto;
  width: 35px;
  height: 35px;
  background: #c70000;
  border: 1px solid #c70000;
  border-radius: 4px;
  opacity: 1 !important;
  line-height: 0;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.theme-Roundedmodal .modal-header .close:hover {
  background: #979797;
}
.bg-pink {
  background: #ff8080 !important;
}
input[readonly],
input:disabled {
  background: #e8e8e8 !important;
}
input[readonly]::-webkit-input-placeholder,
input:disabled::-webkit-input-placeholder {
  color: #b8b8b8 !important;
}
input[readonly]::-moz-placeholder,
input:disabled::-moz-placeholder {
  color: #b8b8b8 !important;
}
input[readonly]:-ms-input-placeholder,
input:disabled:-ms-input-placeholder {
  color: #b8b8b8 !important;
}
input[readonly]:-moz-placeholder,
input:disabled:-moz-placeholder {
  color: #b8b8b8 !important;
}
input.link-style {
  border: 0;
  margin: 0;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: calc(100% - 20px);
  cursor: pointer;
}
.datePicker.form-control.field-bx-sty input {
  border: 0;
  margin: 0;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 22px; /* width:calc(100% - 20px); */
  width: 100%;
  cursor: pointer;
  padding: 6px 23px;
}
/* .datePicker {position: relative;} */
.field-bx-sty {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.datePicker .react-datepicker-wrapper .react-datepicker__input-container:after {
  content: "";
  border-color: transparent;
  border-top-color: #250a72;
  border-width: 6px;
  border-style: solid;
  display: inline-block;
  border-bottom-width: 0;
  margin-right: 7px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}
.cust-select-wrap .select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 20px;
}
.cust-select-wrap {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #414042;
  width: 100%;
  height: 25px;
  max-width: 170px;
  min-width: 130px;
}
.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 4px 10px;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  font-weight: 600;
  font-size: 12px;
  color: #6f7276;
}
.select-styled:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #6f7276 transparent transparent;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  border-bottom: none;
}
.select-styled:hover {
  background-color: #fafafa;
}
.select-styled:active,
.select-styled.active {
  background: rgba(37, 10, 114, 0.78);
  color: #fff;
}
.select-styled:active:after,
.select-styled.active:after {
  border-color: #fff transparent transparent;
}
.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #c4c4c4;
}
.select-options li {
  margin: 0;
  padding: 5px 0;
  text-indent: 10px;
  border-top: 1px solid #cecdcd;
  font-size: 12px;
  -o-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}
.select-options li:hover {
  background: rgba(37, 10, 114, 0.78);
  color: #fff;
}
.select-options li[rel="hide"] {
  display: none;
}
.errorShow-wrap {
  border: 2px dashed #5a5959;
  padding: 10px 20px;
}
.errorShow-wrap p {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.loadmore img {
  width: 70px;
}
/* Table Dark head */
.rounded-dark-head tr th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  -webkit-box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset 2px 0 0 #4a4a4a;
  box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset 2px 0 0 #4a4a4a;
}
.rounded-dark-head tr th {
  -webkit-box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset 0 0 0 #4a4a4a;
  box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a, inset 0 0 0 #4a4a4a;
  background: #979797;
  font-weight: 400;
  font-size: 16px;
  color: #f9f9f9;
  padding: 8px 12px;
}
.rounded-dark-head tr th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset -2px 0 0 #4a4a4a;
  box-shadow: inset 0 -2px 0 #4a4a4a, inset 0 2px 0 #4a4a4a,
    inset -2px 0 0 #4a4a4a;
}
tbody.spacer:before {
  line-height: 1em;
  content: ".";
  display: block;
}
.dash-ord-content.ordContent-table.cust-turnover table tbody tr td:last-child {
  text-align: center;
  width: 100px;
  min-width: 100px;
}
.table-default-sty tr td {
  background: #ebebeb;
}
.btn.tb-btn-rounded {
  background: transparent;
  padding: 5px 15px;
  border: 2px solid transparent;
  color: #fff;
  font-weight: 500;
}
.btn.tb-btn-rounded.green {
  background: #8bc34a;
  border-color: #8bc34a;
}
.btn.tb-btn-rounded.red {
  background: #fa5b50;
  border-color: #fa5b50;
}
.btn.tb-btn-rounded.orng {
  background: #e6a935;
  border-color: #e6a935;
}
.btn.tb-btn-rounded.green:hover {
  background: transparent;
  color: #8bc34a;
}
.btn.tb-btn-rounded.red:hover {
  background: transparent;
  color: #fa5b50;
}
.btn.tb-btn-rounded.orng:hover {
  background: transparent;
  color: #e6a935;
}
.theme-tag-wrap,
.custom-select2 .react-select__multi-value {
  border: 1px solid #979797;
  padding: 4px 8px;
  background: #efefef;
  border-radius: 4px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  color: #424242;
  min-width: 80px;
  margin-right: 2px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}
.custom-select2 .react-select__multi-value__label {
  padding: 0 !important;
  font-size: 13px !important;
  color: #424242 !important;
  font-weight: 500;
  margin-right: 20px;
}
.custom-select2 .react-select__multi-value__remove {
  padding: 0;
  color: #c70000;
  opacity: 0.6;
}
.custom-select2 .react-select__clear-indicator {
  display: none !important;
}
.custom-select2 .react-select__multi-value__remove:hover {
  opacity: 1;
  background-color: transparent;
}
.custom-select2 .react-select__multi-value__remove svg {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.theme-tag-wrap:last-child,
.react-select__multi-value:last-child {
  margin-right: 0;
}
.theme-tag-wrap .close {
  float: right;
  line-height: 1;
  color: #c70000;
  text-shadow: 0 1px 0 #fff;
  font-size: 22px;
  font-weight: 500;
  opacity: 0.6;
  margin-left: 10px;
  cursor: pointer;
}
.theme-tag-wrap .close:hover {
  opacity: 1;
}
.form-input-container {
  position: relative;
  width: 100%;
}
.form-input-container .form-control {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 225px !important;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
  display: none;
}
.form-input-container label {
  width: 100%;
  border-bottom: 1px solid #979797;
  height: 40px;
  color: #00000080;
  font-weight: 500;
}
.form-input-container label span {
  display: inline-block;
  width: calc(100% - 120px);
}
.form-input-container label:after {
  content: "Choose File";
  display: block;
  margin: 0 auto;
  padding: 7px 15px;
  background: #efefef;
  color: #000;
  font-size: 14px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  height: 36px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border: 1px solid #979797;
  border-radius: 4px;
  margin-bottom: 0;
  margin-right: 0;
  min-width: 115px;
  text-align: center;
}
.form-input-container .form-control.is-invalid + label,
.was-validated .form-input-container .form-control:invalid + label {
  background-image: url(./assets/images/validation-icon.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 7.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.field-label {
  color: #00000080;
  font-weight: 500;
  padding-right: 20px;
}
.btn-copied {
  background: #fff;
  width: 100%;
  margin-top: 7px;
  font-size: 16px;
  font-weight: 600;
  color: #250a72;
  text-align: left;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 42px;
  max-height: 42px;
  overflow: hidden;
}
.btn-copied:hover {
  color: #250a72;
}
.btn-copied i {
  margin-left: 10px;
}
.orderNo-wrap {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.orderNo-wrap span span {
  width: calc(100% - 30px);
}
.orderNo-wrap.copied {
  -webkit-transform: translateY(-34px);
  -ms-transform: translateY(-34px);
  transform: translateY(-34px);
}
.fa-icon .react-datepicker__input-container {
  position: relative;
}
.fa-icon .react-datepicker__input-container:before {
  content: "\f073";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #250a72;
}
.datePicker.form-control.field-bx-sty.fa-icon input {
  padding-left: 30px;
}
.uploadFileBtn-wrap {
  background: url(./assets/images/Upload-link.svg) no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  min-width: 40px;
  padding: 13px 10px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.uploadFileBtn-wrap:hover {
  -webkit-filter: invert(10%) sepia(111%) saturate(5268%) hue-rotate(176deg)
    brightness(46%) contrast(254%);
  filter: invert(10%) sepia(111%) saturate(5268%) hue-rotate(176deg)
    brightness(46%) contrast(254%);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.table-optns-wrap .suppliersTypeList li {
  margin-right: 20px;
}
.table-optns-wrap .suppliersTypeList li:last-child {
  margin-right: 0;
}
.td-checkbx-wrap {
  position: relative;
  display: inline-block;
}
.td-checkbx-wrap input[type="checkbox"] {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.td-checkbx-wrap label {
  background: #ebeae8;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  display: block;
  font-size: 12px;
  padding: 0;
  color: #000;
  text-align: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.td-checkbx-wrap input[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
  line-height: 0.8;
  color: #250a72;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
  cursor: pointer;
}
.tableFoot-content {
  padding: 10px;
}
.tableFoot-content .deli-info-list li {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
}
.tableFoot-content .deli-info-list li:last-child {
  margin-right: 0;
}
.tableFoot-content .deli-info-list li span {
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  display: inline-block;
  margin-left: 5px;
}
.deli-info-items-ctn label {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  margin-right: 12px;
  margin-bottom: 0;
}
.deli-info-items-ctn .form-control {
  background: #fff;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  display: block;
  height: 100%;
  min-height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  padding: 0 10px;
  width: 170px;
  overflow: hidden;
}
.deli-info-items-ctn .deli-day-info .form-control {
  width: 50px;
}
.tags-par-wrap {
  position: relative;
}
.tags-par-wrap label {
  font-size: 16px;
  color: #00000080;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 15px;
}
.tags-par-wrap .custom-select2.text-bx-wrap.input-type-select {
  min-height: 40px;
}
table.with-action-btns thead th:last-child {
  text-align: center;
  width: 1%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  white-space: nowrap;
}
table.with-action-btns tbody td:last-child {
  text-align: right;
  width: 1%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  white-space: nowrap;
}
.tb-buttons-wrap .tb-btn-rounded {
  min-width: 90px;
}
table .sta-indicator {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.Swal.fire-modal {
  padding: 0 15px;
  background: #f6f6f6;
  border-radius: 15px;
  border: 2px solid #250a72;
  -webkit-transform: translate3d(0, 0, 0);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  transform: translate3d(0, 0, 0);
  visibility: visible !important;
}
.swal2-title {
  color: #565656;
  font-weight: 600;
  font-size: 21px;
}
.swal2-html-container {
  font-size: 14px;
  text-align: center;
  line-height: 23px;
}
.swal2-icon.swal2-success {
  border: 4px solid #8bc34a;
  color: #8bc34a;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #8bc34a;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #8bc34a;
}
.Swal.fire-icon--success__hide-corners {
  background-color: #f6f6f6;
}
.Swal.fire-icon--success:after,
.Swal.fire-icon--success:before {
  background: #f6f6f6;
}
.swal2-icon {
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}
.swal2-styled {
  padding: 9px 24px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 2px solid transparent !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.swal2-confirm.swal2-styled {
  background: #e6a935 !important;
  border: 2px solid #e6a935 !important;
}
.swal2-confirm.swal2-styled:hover,
.swal2-confirm.swal2-styled:not([disabled]):hover {
  background: transparent !important;
  color: #e6a935;
}
.swal2-styled.swal2-cancel {
  background: #607d8b !important;
  border-color: #607d8b !important;
}
.swal2-styled.swal2-cancel:hover,
.swal2-cancel.swal2-styled:not([disabled]):hover {
  background: transparent !important;
  color: #607d8b;
}
.fixed-head {
  position: relative;
}
.fixed-head thead tr th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
.react-select__placeholder {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-right: 5px;
}
.contract-dash-wrap
  .content-box-head.ord-status-head
  .custom-select-menu.custom-select2
  .react-select__placeholder,
.contract-dash-wrap
  .content-box-head.ord-status-head
  .custom-select-menu
  .react-select__single-value {
  font-size: 12px;
}
.themeRounded-modal .theme-modal-innr {
  border-radius: 30px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
}
.themeRounded-modal .theme-modal-innr.rat-us-mod {
  position: relative;
  z-index: 1;
}
.themeRounded-modal .theme-modal-innr.rat-us-mod:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/images/stars-bg.svg) no-repeat;
  background-size: 80px;
  background-position: top right;
  z-index: -1;
}
.btn.tb-btn-rounded.rounded {
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 30px !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  margin-top: 20px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.themeRounded-modal .theme-modal-innr h5 {
  font-weight: 700;
  color: #5a5a5a;
}
.themeRounded-modal .theme-modal-innr p.grey {
  font-size: 13px;
  color: #a0a0a0;
}
/* rating star */
.rating-star-wrap {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
}
.rating {
  border: none;
  display: inline-block;
}
.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}
.rating > .half:before {
  content: "\f089";
  position: absolute;
}
.rating > label {
  color: #ddd;
  float: right;
}
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
}
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}
.starRating .rating > label:before {
  margin: 0px 3px;
}
.starRating .rating > label {
  margin: 0;
  pointer-events: none;
  cursor: not-allowed;
}

/* Custom Tabs Style */
.conditional-content-wrap .nav-tabs {
  border-bottom: none;
  margin-bottom: 20px;
}
.conditional-content-wrap .nav-tabs .nav-item .nav-link {
  color: #206f9e;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 4px !important;
  padding: 4px 15px;
  font-size: 15px;
  border: 2px solid #206f9e;
  margin-right: 7px;
}
.conditional-content-wrap .nav-tabs .nav-link.active,
.conditional-content-wrap .nav-tabs .nav-item.show .nav-link {
  background-color: #206f9e;
  color: #fff;
}
.conditional-content-wrap .nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0;
}
.form-title {
  font-size: 18px;
}
.button-round-td {
  width: 90px;
}
.theme-year-picker {
  display: inline-block;
}
.theme-year-picker .react-datepicker__input-container {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #600;
  padding: 2px 8px;
  position: relative;
}
.theme-year-picker .react-datepicker__input-container input {
  background-color: transparent;
  color: #630000;
  width: 100%;
  border: none !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.theme-year-picker .react-datepicker__input-container:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  border: 4.2px solid transparent;
  border-top-color: #74777b;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-bottom-width: 0;
}
.year-picker-td .theme-year-picker {
  width: 65px;
}
.theme-year-picker .react-datepicker-year-header {
  padding-bottom: 6px;
}
.theme-year-picker .react-datepicker__year .react-datepicker__year-text {
  width: calc(50% - 4px);
}
.theme-year-picker .react-datepicker__day--keyboard-selected,
.theme-year-picker .react-datepicker__month-text--keyboard-selected,
.theme-year-picker .react-datepicker__quarter-text--keyboard-selected,
.theme-year-picker .react-datepicker__year-text--keyboard-selected {
  border-radius: 2px;
}
.theme-year-picker .react-datepicker__navigation {
  top: 8px;
}
.custom-select-menu.text-bx-wrap.custom-select2.input-type-select.form-group {
  z-index: 99999;
}
.pagin-select-menu .dropdown-toggle {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 22px !important;
}
.country-select-td .custom-select2.text-bx-wrap.input-type-select {
  width: 110px;
  border-bottom: none;
  margin: 0 auto !important;
}
.country-select-td
  .custom-select2.text-bx-wrap.input-type-select
  .react-select__placeholder,
.country-select-td .custom-select-menu .react-select__single-value {
  font-size: 12px;
  color: #74777b !important;
}
.country-select-td
  .custom-select-menu.custom-select2
  .react-select__indicator.react-select__dropdown-indicator {
  border-width: 5px;
}
.auto-compl-select .form-control {
  max-width: 100%;
  width: 100%;
}
.auto-compl-select {
  width: 100%;
  min-width: 140px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.auto-comp-menus {
  padding: 6px 10px;
  font-weight: 400;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__placeholder {
  font-weight: 500;
  color: #495057a6;
}
.custom-select2.text-bx-wrap.input-type-select .react-select__single-value {
  color: #495057;
}
.foating-labels-sty {
  position: absolute;
  font-weight: 500;
  color: #00000080;
  top: 9px;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.danger {
  color: red !important;
}
.foating-labels-chk-sty {
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 500;
  /* color: #00000080; */
}
.floating-label-wrap {
  position: relative;
}
.floating-label-wrap .form-control:focus + .foating-labels-sty,
.floating-label-wrap
  .form-control:not(:placeholder-shown)
  + .foating-labels-sty {
  -webkit-transform: translate3d(-4%, -100%, 0) scale(0.9);
  transform: translate3d(-4%, -100%, 0) scale(0.9);
  opacity: 1;
}
/* .floating-label-select-wrap.input-type-select.custom-select-menu .react-select__placeholder {opacity: 0;}
.floating-label-select-wrap .foating-labels-sty {top: -2px;}
.floating-label-select-wrap .react-select__single-value + .foating-labels-sty {-webkit-transform:translate3d(-4%,-100%,0) scale(0.9);transform:translate3d(-4%,-100%,0) scale(0.9);opacity:1} */
.floating-label-wrap
  .form-control:focus
  + grammarly-extension
  + .foating-labels-sty,
.floating-label-wrap
  .form-control:not(:placeholder-shown)
  + grammarly-extension
  + .foating-labels-sty {
  -webkit-transform: translate3d(-4%, -100%, 0) scale(0.9);
  transform: translate3d(-4%, -100%, 0) scale(0.9);
  opacity: 1;
}

.floating-label-wrap .form-control::-webkit-input-placeholder {
  color: #00000080;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.floating-label-wrap .form-control::-moz-placeholder {
  color: #00000080;
  opacity: 0;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.floating-label-wrap .form-control:-ms-input-placeholder {
  color: #00000080;
  opacity: 0;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.floating-label-wrap .form-control:-moz-placeholder {
  color: #00000080;
  opacity: 0;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.floating-label-wrap .form-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.floating-label-wrap .form-btn .themeReguler-btn {
  min-width: 60px;
  padding: 5px 20px;
  font-size: 13px;
}
.input-tags-lis .form-control {
  padding-right: 80px;
}
.tooltip.show {
  opacity: 1;
  width: auto;
}
.tooltip-inner {
  background: #424242;
  border: 1px solid #4a4a4a;
  color: #fff;
  opacity: 1;
  font-size: 10px;
  font-weight: 400;
  max-width: 350px;
  width: 100%;
  font-family: "Montserrat";
  line-height: 17px;
  letter-spacing: 0.6px;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #424242;
}
.btn-dlt-tab,
.btn-edit-tab,
.btn-info-tab,
.btn-block-tab {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-info-tab {
  background: url(./assets/images/info-icon.svg) no-repeat center;
  background-size: 20px;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  background-position: center;
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.btn-info-tab:hover {
  opacity: 1;
  filter: invert(33%) sepia(89%) saturate(536%) hue-rotate(163deg)
    brightness(90%) contrast(88%);
}
.btn-block-tab {
  background: url(./assets/images/block-icons.svg) no-repeat center;
  background-size: 20px;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  background-position: center;
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.btn-block-tab:hover,
.btn-block-tab.active,
.btn-block-tab:active {
  opacity: 1;
  filter: invert(33%) sepia(89%) saturate(536%) hue-rotate(163deg)
    brightness(90%) contrast(88%);
}
.without-value {
  pointer-events: none !important;
}
.without-value::-webkit-input-placeholder {
  font-weight: 600 !important;
  color: #000 !important;
  opacity: 1 !important;
}
.without-value::-moz-placeholder {
  font-weight: 600 !important;
  color: #000 !important;
  opacity: 1 !important;
}
.without-value:-ms-input-placeholder {
  font-weight: 600 !important;
  color: #000 !important;
  opacity: 1 !important;
}
.without-value:-moz-placeholder {
  font-weight: 600 !important;
  color: #000 !important;
  opacity: 1 !important;
}
.noData-found {
  font-size: 12px;
  text-align: center;
}
.small-unit40 {
  width: 40px !important;
  min-width: 40px !important;
}
.small-unit50 {
  width: 50px !important;
  min-width: 50px !important;
}
.small-unit60 {
  width: 60px !important;
  min-width: 60px !important;
}
.small-unit70 {
  width: 70px !important;
  min-width: 70px !important;
}
.small-unit80 {
  width: 80px !important;
  min-width: 80px !important;
}
.small-unit90 {
  width: 90px !important;
  min-width: 90px !important;
}
.small-unit100 {
  width: 100px !important;
  min-width: 100px !important;
}
.small-unit110 {
  width: 110px !important;
  min-width: 110px !important;
}
.small-unit120 {
  width: 120px !important;
  min-width: 120px !important;
}

.theme-table-wrap2 {
  background: #ebebeb;
  overflow-x: auto;
}
.theme-table-wrap2 thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  font-size: 13px;
  font-weight: 600;
  background: #f2f2f2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-top: 0 !important;
}
.theme-table-wrap2 tbody td {
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
}
.theme-table-wrap2.short-table tbody td {
  padding: 8px 12px;
  font-size: 12px;
  color: #747474;
  max-width: 350px;
}
.theme-table-wrap2 tbody tr:nth-child(even) td {
  background: #f2f2f2;
}
.border-in {
  border-top: 2px solid #0399ac;
}
.border-th-in thead th {
  border-top: 2px solid #0399ac !important;
}
.sticky-th-border thead th {
  -webkit-box-shadow: 0px 1px 0px 0px #0399ac;
  box-shadow: 0px 1px 0px 0px #0399ac;
  border-width: 1px;
}
.catalouge-details-wrap {
  background: #ebebeb;
  padding: 20px;
}
.table-in-dash {
  /* max-height: 123px; */
  overflow: auto;
  /* min-height: 123px; */
  margin-top: 6px;
}
.table-in-dash .theme-table-wrap2 tbody td {
  font-size: 11px;
  padding: 6px 12px;
}
.table-in-dash .theme-table-wrap2 thead th {
  font-size: 12px;
  padding: 9px 12px;
  border-bottom: 0;
  color: rgb(83, 83, 83);
  background-color: #dbdbdb;
}
.highlight-bg,
.all-td-highlight td {
  background-color: #038798 !important;
  border-color: #059db0 !important;
}
.disable-bg,
.all-td-disable td {
  background-color: #09abc0 !important;
  border-color: #0bbed5 !important;
  opacity: 0.5;
}
.sendOrder-wrap .themeReguler-btn {
  padding: 11px 15px 10px;
  font-size: 16px;
  min-width: 120px;
}
.disable-wrap {
  opacity: 0.5;
  cursor: not-allowed !important;
}

/* Button inner loader */
/* .btn-inn-loader {
	color: transparent;
	pointer-events: none !important;
}
.btn-inn-loader:after {
	background:url(./assets/images/svg-loader.svg) no-repeat center;
    content: '';
    background-size: 35px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	pointer-events: none;
} */
/* User Menu Options Style */
.user-menu-opt .btn-secondary {
  background: #ebebeb;
  border: 1px solid #000;
  width: 59px;
  min-width: 59px;
  height: 59px;
  border-radius: 50%;
  padding: 0 !important;
  margin-bottom: 8px;
  overflow: hidden;
  outline: none !important;
  box-shadow: none !important;
}
.user-menu-opt .btn-secondary img {
  width: 100%;
}
.user-menu-opt .dropdown-menu.show {
  transform: none !important;
  left: auto !important;
  right: 0 !important;
  top: 63px !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  background: #ebebeb;
  border-radius: 8px;
  border-color: #000;
  box-shadow: 0px 4px 4px #0000004a;
  font-size: 14px;
  padding: 0;
  min-width: max-content;
}
.user-menu-opt .dropdown-item {
  border-bottom: 1px solid #f0f0f0;
  padding: 6px 12px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.user-menu-opt .dropdown-item:last-child {
  border-bottom: none;
}
.user-menu-opt .dropdown-item.active,
.user-menu-opt .dropdown-item:active,
.user-menu-opt .dropdown-item:focus,
.user-menu-opt .dropdown-item:hover {
  color: #206f9e;
  background-color: #f8f9fa;
}
.user-menu-opt .dropdown-menu:after {
  content: "";
  position: absolute;
  top: -12px;
  right: 5px;
  width: 10px;
  height: 10px;
  border: 6px solid transparent;
  border-bottom-color: #000 !important;
}
input:read-only {
  cursor: not-allowed;
}
/* Logout Button style */
/* .user-menu-opt .btn-secondary {
	background: #ebebeb;
	border: 1px solid #250A72;
	width: 59px;
	min-width: 59px;
	height: 59px;
	border-radius: 50%;
	padding: 0 !important;
	margin-bottom: 8px;
	overflow: hidden;
	outline: none !important;
	box-shadow: none !important;
	color: #250A72;
	transition:all 0.3s ease-in-out;
	position: relative;
	font-size: 21px;
	box-shadow: 0 0 4px #583a81 !important;
	line-height: 23px;
	-webkit-transition:all 0.3s ease-in-out;
	-moz-transition:all 0.3s ease-in-out;
	-ms-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
}
.user-menu-opt .btn-secondary .fas {
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.user-menu-opt .btn-secondary span {
	position: absolute;
	font-size: 14px;
	font-weight: 600;
	display: block;
	width: 100%;
	transform: translate(100% , -8px);
	-webkit-transform: translate(100% , -8px);
	-moz-transform: translate(100% , -8px);
	-ms-transform: translate(100% , -8px);
	-o-transform: translate(100% , -8px);
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.user-menu-opt .btn-secondary:hover,.user-menu-opt .btn-secondary:focus {
	border-radius: 10px;
	width: 70px;
	min-width: 70px;
}
.user-menu-opt .btn-secondary:hover .fas,.user-menu-opt .btn-secondary:focus .fas {
	transform: translateY(-8px);
	font-size: 17px;
	-webkit-transform: translateY(-8px);
	-moz-transform: translateY(-8px);
	-ms-transform: translateY(-8px);
	-o-transform: translateY(-8px);
}
.user-menu-opt .btn-secondary:hover span,.user-menu-opt .btn-secondary:focus span {
	transform: translate(0 , -8px);
	-webkit-transform: translate(0 , -8px);
	-moz-transform: translate(0 , -8px);
	-ms-transform: translate(0 , -8px);
	-o-transform: translate(0 , -8px);
} */
.dash-pg-inn-wrap.demo-header-wrap {
  padding: 15px 30px;
  min-height: max-content;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
  background: #250a72db;
  border: 1.5px solid #583a81;
}
.demo-headTitle {
  font-size: 23px;
  font-weight: 600;
}
.welcome-inn-content {
  text-align: center;
  font-size: 18px;
  color: #250a72;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.inn-pageLogo-wrap {
  width: 150px;
}
.welcome-inn-content p {
  margin-bottom: 25px;
}
.welcome-inn-content h2 {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 30px;
}
.welcome-btn-wrap {
  margin-top: 60px;
  margin-bottom: 20px;
}
.welcome-btn-wrap .themeReguler-btn {
  font-size: 16px;
  min-width: 130px;
}
/* Theme SquareModal Style */
.theme-squareModal .modal-content {
  border: 1px solid #435d7d;
  border-radius: 4px;
  padding: 4px 7px;
}
.theme-squareModal .model-body {
  background: #ffff;
  border: 2px solid #435d7d;
  border-radius: 6px;
  padding: 0;
  margin-top: 0;
  overflow: hidden;
}
.theme-squareModal .modal-card {
  background: #435d7d;
  color: #fff;
  padding: 10px;
  width: 100%;
}
.theme-squareModal .nutrTable-wrap {
  padding: 0px 20px;
  margin: 0;
  margin-top: 20px;
  max-height: 70vh;
  position: relative;
  margin-bottom: 10px;
}
.theme-squareModal .nutrTable-wrap .table thead th {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 2px solid #f7f7f7;
  border-top: none;
  background: #fff;
  position: sticky;
  top: 0;
}
.theme-squareModal .nutrTable-wrap .table tbody td {
  font-size: 13px;
  padding: 10px 12px;
}
.theme-squareModal .nutrTable-wrap .table tbody tr:nth-child(odd) {
  background: #fcfcfc;
}
.theme-squareModal .modalCard-text {
  font-size: 17px;
  color: #fff;
  font-weight: 500 !important;
}
.circle-btn-style {
  background: url(/static/media/Cancel.7dc242df.svg) no-repeat center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-size: 12px;
  background-color: #c70000 !important;
  opacity: 1;
  border-radius: 50%;
  border: 1px solid #980404 !important;
}
.circle-btn-style:hover {
  background-color: transparent !important;
  border-color: #fff !important;
  box-shadow: 0 0 6px 0px #00000099;
}
.modal-centered .modal-dialog {
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
}
/* Theme filter searchbar style */
.filter-searchbar .form-group {
  margin: 0;
}
.filter-searchbar .form-control {
  outline: none !important;
  box-shadow: none !important;
  height: 100%;
  background-color: transparent;
  padding: 5px 12px;
  border: none;
  border-radius: unset;
  font-size: 13px;
  font-weight: 500;
}
.filter-searchbar input[type="search"]::-webkit-search-cancel-button {
  filter: invert(20%) sepia(0%) saturate(7463%) hue-rotate(171deg)
    brightness(101%) contrast(100%);
  -webkit-filter: invert(20%) sepia(0%) saturate(7463%) hue-rotate(171deg)
    brightness(0%) contrast(100%);
  opacity: 0.6;
  -webkit-appearance: none;
}
.btn.bg-dark-green:hover {
  background-color: #fff !important;
  color: #3c6b04 !important;
}
.form-lg-title {
  font-size: 18px;
  font-weight: 500;
  color: #535353;
}
.with-border {
  border-bottom: 1px solid #979797;
  padding-bottom: 7px;
}
/* Slide checkbox custom style */
.Slide-checkbx {
  width: 40px;
  height: 20px;
  background: transparent;
  margin: 0;
  position: relative;
  border-radius: 50px;
  display: inline-block;
  margin-right: 10px;
}
.Slide-checkbx .indic-line {
  background: #ccc;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.Slide-checkbx label {
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  background: #fff;
  border-radius: 50%;
  transition: all 0.4s ease;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.Slide-checkbx input[type="checkbox"] {
  visibility: hidden;
}
.Slide-checkbx input[type="checkbox"]:checked + label {
  left: 22px;
}
.Slide-checkbx input[type="checkbox"]:checked + label + .indic-line {
  background: #27ae60;
}
.Slide-checkbx.lg-checkbx {
  width: 48px;
  height: 22px;
}
.Slide-checkbx.lg-checkbx label {
  width: 18px;
  height: 18px;
}
.Slide-checkbx.lg-checkbx input[type="checkbox"]:checked + label {
  left: 28px;
}
.Slide-checkbx.grey-checkbx .indic-line {
  background: #fa4b3d00;
  border: 1px solid #bebebe;
}
.Slide-checkbx.grey-checkbx label {
  background: #bebebe;
}
.Slide-checkbx.grey-checkbx input[type="checkbox"]:checked + label {
  background: #fff;
}
.Slide-checkbx.grey-checkbx
  input[type="checkbox"]:checked
  + label
  + .indic-line {
  background: #27ae60;
  border-color: #27ae60;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover {
  border-left-color: #250a72;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover {
  border-right-color: #250a72;
}
.react-datepicker__day:hover {
  background: #cab7ff;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected:hover {
  background: #250a72;
}
.date-range-picker.date-range-holder span {
  height: auto;
}
.date-range-picker.date-range-holder span .react-datepicker-wrapper::after {
  display: none;
}
.date-range-picker.date-range-holder span .react-datepicker__input-container {
  width: 100%;
  max-width: 100%;
}
.system-cat-filter .card-bx-sty.table-card-wrap .btn-yellow {
  padding: 9px 15px;
}
/* Responsive Queries */
@media only screen and (max-width: 1600px) {
  .content-box-head h2,
  .btn.btn-link {
    font-size: 12px;
  }
  .font-weight-bold {
    font-weight: 600 !important;
  }
  .btn-polygon-sty {
    width: 75px;
    height: 75px;
    font-size: 9px;
  }
  .btn-polygon-sty img {
    width: 18px;
    height: 18px;
  }
  .btn-polygon-sty.red img {
    width: 14px;
    height: 14px;
  }
  .figureTitle {
    font-size: 13px;
  }
  .themeReguler-btn {
    padding: 7px 15px 6px;
    font-size: 12px;
    min-width: 150px;
  }
  .sta-indicator {
    width: 15px;
    min-width: 15px;
    height: 15px;
  }
  /* .date-range-picker span {height: 23px;} */
  .date-range-picker span input {
    padding: 3.5px 10px 3px;
    font-size: 11px;
  }
  .table-fz-12 tbody td,
  .table-fz-12 thead th {
    font-size: 11px;
  }
  .fz-14.btn.btn-link {
    font-size: 12px !important;
  }
  table.table-light1 tbody tr td {
    font-size: 12px;
  }
  .rounded-dark-head tr th {
    font-size: 13px;
  }
  tbody.spacer:before {
    line-height: 4px;
  }
  .custom-select2.text-bx-wrap.input-type-select .react-select__placeholder,
  .custom-select-menu .react-select__single-value {
    font-size: 13px;
  }
  .custom-select2.text-bx-wrap.input-type-select .react-select__option {
    font-size: 12px;
    padding: 5px 12px;
  }
  .custom-select2.text-bx-wrap.input-type-select
    .pagin-select-menu
    .dropdown-menu.filter-drop
    li {
    font-size: 12px;
  }
  .custom-select2.text-bx-wrap.input-type-select
    .react-select__indicator.react-select__dropdown-indicator {
    border-width: 5px;
  }
  .custom-select2.text-bx-wrap.input-type-select
    .pagin-select-menu
    .dropdown-toggle {
    font-size: 13px;
  }
  .tags-par-wrap label,
  .form-input-container label {
    font-size: 13px;
  }
  .form-input-container label:after {
    padding: 5px 15px;
    min-width: 100px;
    height: 29px;
    font-size: 12px;
  }
  .form-btn .themeReguler-btn {
    padding: 6px 15px 6px;
    font-size: 12px;
    min-width: 120px;
  }
  .btn-sty-title {
    font-size: 28px;
  }
  .logoHolder img {
    width: 150px;
  }
  .pageWrapper .pageGoBackBtn {
    min-width: 110px;
    font-size: 14px;
    padding: 5px 20px;
  }
  .custom-select2.text-bx-wrap {
    height: 30px;
    padding: 0;
  }
  .custom-select2.text-bx-wrap.input-type-select {
    padding: 6.5px 0;
    height: auto;
  }
  .custom-select-menu.custom-select2 .react-select__placeholder,
  .custom-select-menu .react-select__single-value {
    font-size: 12px;
  }
  .custom-select-menu.custom-select2
    .react-select__indicator.react-select__dropdown-indicator {
    border-width: 5px;
  }
  .custom-select-menu .react-select__control {
    padding: 0 10px;
  }
  .tb-buttons-wrap .tb-btn-rounded {
    min-width: 75px;
  }
  .btn.tb-btn-rounded {
    padding: 4px 10px;
    font-size: 12px;
  }
  .field-bx-sty {
    min-height: 30px;
    height: 30px;
    font-size: 13px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 14px;
  }
  .react-datepicker__header,
  .react-datepicker__month {
    font-size: 11px;
  }
  .label-bold {
    font-size: 15px;
  }
  .title-dark-20 {
    font-size: 16px;
  }
  .card-bx-sty.box-item-inn h1 {
    font-size: 12px;
  }
  .card-bx-sty.box-item-inn p {
    font-size: 11px;
  }
  .sup-Oview-list {
    font-size: 12px;
  }
  .sup-Oview-list li .btn-sm-rounded {
    font-size: 11px;
    font-weight: 500;
    padding: 1px 9px;
  }
  .supp-Oview-wrap .ordCatg-InnTable table tbody tr td .whi-round {
    font-size: 11px;
    padding: 4px 9px;
  }
  .cst-checkBx-sty.form-group label:before {
    padding: 9px;
  }
  .fz-28 {
    font-size: 22px !important;
  }
  .theme-Roundedmodal .modal-content .modal-card label {
    font-size: 14px;
  }
  .modalCard-text {
    font-size: 11px;
  }
  .modalCard-text.font-weight-bold {
    font-size: 11px;
    font-weight: 500 !important;
  }
  .modalTable-wrap .table thead th {
    font-size: 10px;
  }
  .modalTable-wrap .table tbody td {
    font-size: 10px;
    padding: 1px 12px;
  }
  .modalTable-wrap .table tbody td .rounded-td-wrap {
    font-size: 10px;
  }
  .modalTable-wrap .table tbody:before {
    content: "";
    margin-top: 10px;
    display: block;
  }
  .modalTable-wrap .table thead th:nth-child(2),
  .modalTable-wrap .table thead th:nth-child(3) {
    width: 80px;
    min-width: 80px;
  }
  .nutr-dtls-info.nutr-type {
    font-size: 10px;
    padding: 4px 15px;
  }
  .theme-Roundedmodal .modal-content .modal-card.bg-green .modalCard-text {
    font-size: 13px;
  }
  .nutr-indicator-wrap li:first-child,
  .nutr-order-wrap li:first-child {
    font-size: 10px;
  }
  .nutr-indicator-wrap li,
  .nutr-order-wrap li {
    padding: 3px 10px;
    font-size: 10px;
    min-height: 22px;
  }
  .modal-btn-wrap .footer-close-btn.close {
    font-size: 13px;
  }
  .nutr-dtls-info {
    font-size: 10px;
    padding: 13px 15px;
  }
  .swal2-popup {
    width: 380px;
    padding: 0;
  }
  .swal2-icon {
    -webkit-transform: scale(0.7) !important;
    -ms-transform: scale(0.7) !important;
    transform: scale(0.7) !important;
    margin: 15px auto !important;
  }
  .swal2-title {
    font-size: 17px !important;
    padding-top: 5px !important;
  }
  .swal2-html-container {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .swal2-styled {
    font-size: 12px !important;
    padding: 7px 20px !important;
  }
  .themeRounded-modal .theme-modal-innr h5 {
    font-size: 18px;
    margin-bottom: 12px !important;
  }
  .themeRounded-modal .theme-modal-innr p.grey {
    font-size: 12px;
  }
  .btn.tb-btn-rounded.rounded {
    font-size: 12px;
    padding: 8px 17px;
    margin-top: 15px;
  }
  .themeRounded-modal .theme-modal-innr {
    padding: 5px 40px 35px !important;
  }
  .swal2-actions {
    padding-bottom: 20px;
  }
  .tableFoot-content .deli-info-list li,
  .deli-info-items-ctn label {
    font-size: 12px;
  }
  .deli-info-items-ctn .form-control {
    min-height: 24px;
  }
  .country-select-td
    .custom-select2.text-bx-wrap.input-type-select
    .react-select__placeholder,
  .country-select-td .custom-select-menu .react-select__single-value {
    font-size: 11px;
  }
  .country-select-td
    .custom-select-menu.custom-select2
    .react-select__indicator.react-select__dropdown-indicator {
    border-width: 4.5px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.2rem;
    line-height: 1.2rem;
  }
  .react-datepicker__navigation {
    border: 0.4rem solid transparent;
  }
  .datePicker.form-control.field-bx-sty input {
    height: 26px;
  }
  .customerListing.datePicker.form-control.field-bx-sty input {
    height: auto;
  }
  .auto-comp-menus {
    font-size: 13px;
  }
  .foating-labels-sty {
    font-size: 13px;
  }
  .theme-tag-wrap,
  .custom-select2 .react-select__multi-value {
    height: 23px;
    font-size: 11px;
    padding: 3px 6px;
  }
  .floating-label-wrap .form-btn .themeReguler-btn {
    padding: 3px 15px;
    font-size: 11px;
  }
  .input-tags-lis .form-control {
    padding-right: 70px;
  }
  .theme-Roundedmodal .modal-content .modal-card {
    padding: 6px 10px;
    border-radius: 4px;
  }
  .nutri-head-wrap .modalCard-text {
    font-size: 13px;
  }
  .theme-table-wrap2.short-table tbody td {
    font-size: 10px;
  }
  .theme-table-wrap2 thead th {
    font-size: 12px;
    padding: 10px 12px;
  }
  .dtls-short-title {
    font-size: 14px;
  }
  .table-in-dash .theme-table-wrap2 thead th {
    font-size: 11px;
    padding: 8px 12px;
  }
  .contract-dash-wrap .dash-chartInfo-table.contr-figure-info .figureTitle {
    font-size: 13px;
  }
  .table-in-dash {
    margin-top: 5px;
    max-height: 113px;
    min-height: 113px;
  }
  .contract-dash-wrap .dash-chartInfo-table.contr-figure-info {
    min-height: 205px;
  }
  .rating > label:before {
    font-size: 17px;
  }
  .sendOrder-wrap .themeReguler-btn {
    padding: 9px 15px 8px;
    font-size: 13px;
    min-width: 110px;
  }
  .custom-select2 .react-select__multi-value__label {
    font-size: 11px !important;
    margin-right: 10px;
  }
  .custom-select2 .react-select__multi-value__remove svg {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  .btn-info-tab {
    background-size: 16px;
  }
  .user-menu-opt .btn-secondary {
    width: 49px;
    min-width: 49px;
    height: 49px;
    font-size: 18px;
  }
  .user-menu-opt .dropdown-menu.show {
    font-size: 12px;
    top: 55px !important;
  }
  .user-menu-opt .dropdown-item {
    padding: 4px 10px;
  }
  .user-menu-opt .dropdown-menu:after {
    top: -11px;
    border: 5px solid transparent;
  }
  .demo-headTitle {
    font-size: 17px;
  }
  .demo-headDes {
    font-size: 13px;
  }
  .welcome-inn-content {
    font-size: 15px;
  }
  .welcome-inn-content h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .welcome-btn-wrap {
    margin-top: 50px;
  }
  .welcome-btn-wrap .themeReguler-btn {
    padding: 9px 15px 8px;
    font-size: 13px;
    min-width: 110px;
  }
  .welcome-inn-content p {
    margin-bottom: 18px;
  }
  .inn-pageLogo-wrap {
    width: 140px;
  }
  .user-menu-opt .btn-secondary span {
    font-size: 12px;
  }
  .user-menu-opt .btn-secondary:hover .fas,
  .user-menu-opt .btn-secondary:focus .fas {
    font-size: 14px;
  }
  .user-menu-opt .btn-secondary:hover span,
  .user-menu-opt .btn-secondary:focus span {
    transform: translate(0, -11px);
    -webkit-transform: translate(0, -11px);
    -moz-transform: translate(0, -11px);
    -ms-transform: translate(0, -11px);
    -o-transform: translate(0, -11px);
  }
  .user-menu-opt .btn-secondary:hover,
  .user-menu-opt .btn-secondary:focus {
    width: 55px;
    min-width: 55px;
  }
  .theme-squareModal .modalCard-text {
    font-size: 14px;
  }
  .circle-btn-style {
    width: 28px;
    min-width: 28px;
    height: 28px;
    background-size: 11px;
  }
  .theme-squareModal .nutrTable-wrap .table thead th {
    font-size: 12px;
    padding: 8px 12px;
  }
  .theme-squareModal .nutrTable-wrap .table tbody td {
    font-size: 11px;
    padding: 8px 12px;
  }
  .theme-squareModal .nutrTable-wrap {
    padding: 0px 15px;
    margin-top: 12px;
    margin-bottom: 5px;
  }
  .Slide-checkbx label {
    display: block;
    width: 13px;
    height: 13px;
  }
  .Slide-checkbx {
    width: 34px;
    height: 16px;
    margin-right: 5px;
  }
  .Slide-checkbx input[type="checkbox"]:checked + label {
    left: 19px;
  }
  .fz-md-12 {
    font-size: 12px !important;
  }
  .modalTable-wrap.table-responsive {
    max-height: 300px;
  }
  .maxHeight {
    max-height: 780px;
    overflow: auto;
  }
}
@media only screen and (max-width: 1440px) {
  .maxHeight {
    max-height: 690px;
  }
}
@media only screen and (max-width: 1024px) {
  .maxHeight {
    max-height: 641px;
  }
}
@media (max-width: 1600px) and (min-width: 576px) {
  .modal-dialog {
    max-width: 430px;
  }
}
@media only screen and (max-width: 1440px) {
  .logoHolder img {
    width: 170px;
  }
  .welcome-inn-content {
    margin-top: 30px;
    width: 95%;
  }
  .welcome-btn-wrap {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .btn-sty-title {
    font-size: 25px;
  }
  .logoHolder img {
    width: 140px;
  }
}
@media only screen and (max-width: 1025px) {
  .btn-sty-title {
    font-size: 22px;
  }
  .user-menu-opt .btn-secondary {
    width: 42px;
    min-width: 42px;
    height: 42px;
    font-size: 16px;
  }
  .user-menu-opt .dropdown-menu.show {
    top: 50px !important;
  }
  .user-menu-opt .btn-secondary span {
    font-size: 11px;
  }
  .user-menu-opt .btn-secondary:hover .fas,
  .user-menu-opt .btn-secondary:focus .fas {
    font-size: 12px;
  }
  .user-menu-opt .btn-secondary:hover span,
  .user-menu-opt .btn-secondary:focus span {
    transform: translate(0, -14px);
    -webkit-transform: translate(0, -14px);
    -moz-transform: translate(0, -11px);
    -ms-transform: translate(0, -14px);
    -o-transform: translate(0, -14px);
  }
  .user-menu-opt .btn-secondary:hover,
  .user-menu-opt .btn-secondary:focus {
    width: 52px;
    min-width: 52px;
  }
}
@media only screen and (max-width: 992px) {
  .logoHolder img {
    width: 150px;
  }
  .d-flex.flex-fill.mesh-logoWrap {
    width: 100%;
    margin-top: 10px;
  }
  .pageHeader .mesh-control-btns {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .mesh-logoWrap {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .pageHeader .pageHeading {
    padding: 6px 10px;
  }
  .mesh-logoWrap {
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }
  .mesh-logoWrap {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .pageHeader > div {
    padding: 0 0;
  }
  .btn-polygon-sty {
    width: 70px;
    height: 70px;
    font-size: 8px;
  }
  .btn-polygon-sty.red img {
    width: 12px;
    height: 12px;
  }
  .btn-polygon-sty img {
    width: 16px;
    height: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .tooltip-inner {
    max-width: 220px !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog.w-530 {
    max-width: 530px;
  }
  .modal-dialog.w-650 {
    max-width: 530px;
  }
}
@media (min-width: 700px) {
  .modal-dialog.w-650 {
    max-width: 650px;
  }
}

@media only screen and (max-width: 576px) {
  .custom-select-menu .react-select__placeholder,
  .custom-select-menu .react-select__single-value {
    font-size: 14px;
    font-weight: 500;
  }
  .custom-select-menu
    .react-select__indicator.react-select__dropdown-indicator {
    border-width: 6px;
  }
  .btn-sty-title {
    width: 100%;
  }
  .theme-Roundedmodal .modal-card .custom-select-menu.text-bx-wrap,
  .dash-ord-opt-wrap {
    min-width: auto;
    margin-bottom: 0;
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
  }
  .theme-Roundedmodal .modal-content .modal-card label {
    font-size: 18px;
  }
  .modalCard-text {
    font-size: 16px;
  }
  .pageHeader > div {
    padding: 0 0;
  }
  .custom-select2.text-bx-wrap.input-type-select {
    margin-bottom: 0 !important;
  }
  .custom-select2.text-bx-wrap.input-type-select.sm-mb-3 {
    margin-bottom: 30px !important;
  }
  .themeRounded-modal .theme-modal-innr.rat-us-mod:before {
    background-size: 60px;
  }
  .themeRounded-modal .theme-modal-innr h5 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 479px) {
  .nutr-items-wrap {
    width: 100%;
  }
  .nutr-indicator-wrap,
  .nutr-order-wrap {
    margin-left: 0;
    margin-top: 20px;
  }
  .nutrTable-wrap {
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .theme-Roundedmodal .modal-content .modal-card label {
    font-size: 14px;
    margin-right: 20px;
  }
  .modalCard-text.d-flex.align-items-center {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .modalCard-value {
    margin-left: 0;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .modalCard-text {
    font-size: 14px;
  }
  .pageHeader .logoHolder,
  .pageHeader .heading-holder,
  .pageHeader .mesh-control-btns {
    width: 100%;
  }
  .pageHeader .logoHolder {
    text-align: center;
    margin: 0 0 10px;
  }
  .pageHeader .logoHolder img {
    display: inline-block;
    vertical-align: top;
  }
}

.thankyou-header {
  color: #250a72;
  font-size: 16px !important;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0px !important;
  font-weight: 700;
}
.thankyou-image {
  text-align: end;
  justify-content: flex-end;
}

.thankyou-p {
  font-size: 14px;
  color: #250a72;
  margin-bottom: 0.1rem !important;
}
.thankyou-background {
  background-color: #ebebeb !important;
}
.thankyou-blue {
  background-color: #250a72 !important;
  color: #fff !important;
}
.thankyou-footer {
  border-top: 0px !important;
  background-color: transparent;
  padding: 0.5rem 0rem !important;
}
.thankyou-button {
  display: flex;
  justify-content: end;
  align-items: baseline;
  margin-top: 0px !important;
  font-weight: 700 !important;
}

.thankyou-Modal {
  border-radius: 30px;
  padding: 20px;
  text-align: center;
  overflow: hidden;
}
.thankyou-number {
  background-color: #fff;
  color: #250a72;
  border-radius: 50%;
  height: 22px;
  width: 22px;
}
.thankyou-card {
  background-color: #250a72;
  border-radius: 30px;
  padding: 0px;
  height: 100% !important;
}

.thankyou-text {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
}

.thankyou-number-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.thankyou-modal-dialog {
  max-width: 640px !important;
}

label {
  margin-bottom: 0px !important;
}
.thankyou-container {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.thankyou-spacing {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.thankyou-p-footer {
  font-size: 14px;
  color: #250a72;
  margin-bottom: 0.1rem !important;
  text-align: center !important;
}
.thankyou-footer-text {
  justify-content: end;
  display: flex;
  align-items: baseline;
}
.thankyou-comment-box {
  border: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid #fff !important;
  width: 100%;
  color: #fff !important;
}
.thankyou-comment-box:focus-visible {
  outline: 0px !important;
}

.thankyou-number-comment {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.thankyou-text-comment {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  justify-content: start;
}

.thankyou-card-comment {
  background-color: #250a72;
  border-radius: 30px;
  padding-top: 10px;
  height: 100% !important;
  font-size: 13px;
  overflow: hidden;
}

.theme-modal-innr:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: none !important;
  height: 100%;
  background-size: 80px;
  background-position: top right;
  z-index: -1;
}
.order-detail-p {
  font-size: 10px;
  font-weight: normal;
}
.alert-box {
  margin-bottom: 0rem !important;
  text-align: center !important;
}
@media only screen and (max-width: 640px) {
  .thankyou-text {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .thankyou-text-comment {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ml-2 {
  margin-left: 2px;
}
.disableCsvBtn {
  pointer-events: none;
}
.availability {
  justify-content: center;
  display: flex;
}
.px-0 {
  padding: 0.5rem;
}
.chart-title {
  text-align: center;
  padding: 0.5rem;
  font-size: 13px;
  font-weight: bold;
}

.mw-15 {
  max-width: 15rem;
}

.no-border {
  border: 0 !important;
}

.btn.btn-blue {
  background: #0b10a6;
  border: 1px solid #979797;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 9px 15px;
  font-size: 14px;
  color: #f9f9f9;
  font-weight: 500;
}

.btn.btn-blue:hover {
  color: #2429cc;
  background: #fff;
}

.pl-1 {
  padding-left: 1rem !important;
}
