.marketPlaceBottomText{font-weight: 600;font-size: 14px;line-height: 17px;position: absolute;right:5px;bottom:-35px;}
.tabsHeader{display:block;}
.tabsHeader .specialOfferBlock{width:15%;}
.tabsHeader .filterSelectHolder{width:20%;}
.tabsHeader .nav-tabs{border-bottom:none;}
.tabsHeader .nav-tabs a{background: #250A72;border:1px solid #250A72;border-radius: 10px;font-weight:500;font-size: 20px;line-height: 24px;color:#fff;padding:15px;-o-transition:all ease-in-out 0.2s;-webkit-transition:all ease-in-out 0.2s;transition:all ease-in-out 0.2s;}
.tabsHeader .nav-tabs .nav-link.active,.tabsHeader .nav-tabs .nav-link:hover{background:none;border-color:#250A72;color:#250A72}
.marketPlaceItems .marketPlaceItem{background: #EBEBEB;border: 1px solid #EBEBEB;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;padding:0 15px;}
.marketPlaceItems .imageHolder{padding:10px 0;}
.marketPlaceItems .marketProductHolder{width:156px;}
.marketPlaceItems .marketPlaceLogoHolder{width:85px;}
.marketPlaceItems .locationName{font-weight: bold;font-size: 24px;line-height: 29px;color: #250A72;position:relative;}
.marketPlaceItems .locationName:before{width:39px;height:32px;content:"";background:url(../../assets/images/location-icon.png);background-size:contain;position:absolute;left:-45px;top:0;}
.marketPlaceItems .nameHolder{font-weight: bold;font-size: 24px;line-height: 29px;color: #250A72;}
.marketPlaceItems .priceHolder{font-weight: bold;font-size: 26px;line-height: 32px;color: #F93D3D;}
.dateDetailHolder .validToDate{font-weight: bold;font-size: 18px;line-height: 22px;color: #F93D3D;-webkit-transform: matrix(0.9, 0.35, -0.48, 0.93, 0, 0);-ms-transform: matrix(0.9, 0.35, -0.48, 0.93, 0, 0);transform: matrix(0.9, 0.35, -0.48, 0.93, 0, 0);margin:40px 0 0;height:85px;}
.dateDetailHolder .viewDetailLink{font-weight: 600;font-size: 20px;line-height: 24px;color:#250A72;}
@media only screen and (max-width:1440px){
    .marketPlaceItems .nameHolder{font-size:22px;line-height:26px;}
}
@media only screen and (max-width:1366px){
    .tabsHeader .specialOfferBlock{width:9%;}
}
@media only screen and (max-width:1200px){
    .tabsHeader .nav-tabs a{padding:10px;font-size:18px;}
}
@media only screen and (max-width:992px){
    .dateDetailHolder .validToDate{-webkit-transform: matrix(1, 0, 0, 0.93, 0, 0);-ms-transform: matrix(1, 0, 0, 0.93, 0, 0);transform: matrix(1, 0, 0, 0.93, 0, 0);margin:0 5px 5px 0;height:auto;}
    .specialOfferBlock{display:none;}
    .tabsHeader .filterSelectHolder{width:100%;max-width: 100%;}
    .tabsHeader .filterSelectHolder .custom-select-menu.custom-select2{max-width: 100%;;}
}
@media only screen and (max-width:768px){
    .marketPlaceItems .nameHolder,
    .dateDetailHolder .viewDetailLink{font-size:18px;line-height:22px;}
    .marketPlaceBottomText{position:relative;bottom: auto;right:auto;}
}
@media only screen and (max-width: 576px){
    .tabsHeader .nav-tabs a {
        padding: 5px 10px;
        font-size: 16px;
    }
}