.dash-page-content {
  min-height: 100vh;
  background: url(../../../assets/images/login-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  width: calc(100% - 220px);
  max-width: 100%;
  padding: 20px;
  max-height: 100vh;
  overflow: auto;
}
.dash-pg-inn-wrap {
  background: rgba(249, 249, 249, 0.7);
  border: 1.5px solid #979797;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 100%;
  padding: 20px 30px 24px;
}
.dash-page-content .mesh-board-wrap .btn-sty-title {
  margin-right: 20px;
}
.content-box-wrap {
  background: #d3d3d3;
  border: 1px solid #000;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 15px 20px;
  height: auto;
}
.content-box-head {
  background: #979797;
  border: 2px solid #4a4a4a;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px 20px;
}
.content-box-head h2,
.btn.btn-link {
  font-weight: 400;
  font-size: 16px;
  color: #f9f9f9;
  line-height: 25px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
.btn.btn-link {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.sta-indicator {
  background: transparent;
  width: 18px;
  min-width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.sta-indicator.appr {
  background: #8bc34a;
}
.sta-indicator.past {
  background: #fa5b50;
}
.sta-indicator.rfq {
  background: #2d8cc5;
}
.sta-indicator.pend {
  background: #feee61;
}
.dash-ord-dtls {
  margin-top: 20px;
  max-height: 120px;
  overflow: auto;
}
.dash-ord-dtls li {
  background: #ebebeb;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 10px;
}
.dash-ord-dtls li span {
  display: inline-block;
}
.dash-ord-dtls li:last-child {
  margin-bottom: 0;
}
.dash-ord-content {
  max-width: calc(100% - 30px);
  width: calc(100% - 30px);
  margin-right: 30px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-align: right;
}
.dash-ord-opt-wrap {
  min-width: 120px;
  width: 100%;
  max-width: 215px;
  margin-right: 15px;
}
.text-bx-wrap {
  border-radius: 4px;
  background: #250a72;
  font-weight: 600;
  font-size: 16px;
  color: #f9f9f9;
  padding: 10px 20px;
  margin: 0;
}
.dash-new-ord-wrap {
  margin-top: 15px;
}
.dash-ord-opt-wrap .form-control {
  background: #f9f9f9;
  border: 2px solid #4a4a4a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  min-height: 40px;
}
.dash-ord-opt-wrap .form-control::-webkit-input-placeholder {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 600;
}
.dash-ord-opt-wrap .form-control::-moz-placeholder {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 600;
}
.dash-ord-opt-wrap .form-control:-ms-input-placeholder {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 600;
}
.dash-ord-opt-wrap .form-control:-moz-placeholder {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 600;
}
.btn-order-sty {
  font-weight: 700;
  font-size: 14px;
  color: #f9f9f9;
  background: #85c33d;
  border: 1px solid #979797;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  border: 1px solid #85c33d;
}
.btn-order-sty:hover {
  background: #fff;
  color: #85c33d;
}
.btn-order-sty.focus,
.btn-order-sty:focus {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.dash-ord-dtls.dash-ord-list {
  max-height: 220px;
}
.dash-ord-dtls.dash-ord-list li {
  min-height: 100px;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  padding: 10px;
}
.dash-map-content-wrap h2 {
  font-size: 14px;
  color: #4a4a4a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 30px;
  border-bottom: 1px solid #4a4a4a;
  padding-bottom: 10px;
  width: 90%;
}
.dash-map-content-wrap h2 span {
  display: inline-block;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.map-bx-wrap {
  padding: 20px 20px 0;
  text-align: center;
}
.dash-map-content-wrap .map-bx-wrap {
  min-height: 200px;
}
.mash-info-text {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-top: 20px;
  width: 100%;
  text-align: right;
}
.btn.btn-link.sidebar-toggle {
  background: #4a4a4a;
  position: fixed;
  top: 0;
  left: 100%;
  -webkit-transform: translate(-50%) rotate(45deg);
  -ms-transform: translate(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
  width: 35px;
  height: 35px;
  padding: 0;
  text-align: center;
  display: none;
}
.btn.btn-link.sidebar-toggle img {
  -webkit-transform: translate(-39%, -21%) rotate(135deg);
  -ms-transform: translate(-39%, -21%) rotate(135deg);
  transform: translate(-39%, -21%) rotate(135deg);
  width: 27%;
  margin: 0 auto;
  margin-right: 0;
  line-height: 10px;
  height: 100%;
}
.dash-ord-dtls.dash-ord-list .ordTable-btn {
  margin: 0 auto;
  margin-right: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #000;
  width: 100%;
}
.dash-ord-dtls.dash-ord-list .ordContent-table table tbody tr td {
  font-weight: normal;
  font-size: 11px;
  color: #000;
  text-align: left;
  border: none;
  padding: 4px 12px;
}
.dash-ord-content.ordContent-table {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
}
.dash-ord-content.ordContent-table.cust-turnover table tbody tr td:last-child {
  text-align: center;
  width: 100px;
  min-width: 100px;
}
.dash-ord-content.ordContent-table.cust-turnover
  table
  tbody
  tr
  td:nth-last-of-type(2) {
  text-align: right;
}
/* Mesh Borad Style */
.mesh-board-wrap .dash-page-content {
  width: 100%;
  padding: 0;
  background: none;
  min-height: 100%;
}
.mesh-boardPort-list li {
  border-bottom: 1px solid #4a4a4a;
  padding: 12px 6px 3px;
}
.mesh-boardPort-list {
  margin-bottom: 0;
  margin-top: 30px;
  max-height: 320px;
  overflow: auto;
}
.mesh-boardPort-list .btn.btn-links {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0;
}
.mesh-boardPort-list .btn.btn-links:hover {
  color: #250a72;
}
.mesh-board-wrap .content-box-wrap {
  padding: 10px 20px 20px;
}
.dash-map-content-wrap.meshBoard-map {
  height: calc(100% - 35px);
}
.dash-map-content-wrap.meshBoard-map .map-bx-wrap {
  height: 100%;
  padding: 0;
  padding-top: 20px;
  min-height: 320px;
}
.dash-map-content-wrap.meshBoard-map .jvectormap-container {
  height: 100%;
}
.meshBoard-main-wrap .dash-pg-inn-wrap {
  min-height: 80vh;
}
/* New Design DashBoard style */
.dashNew-ord-wrap .content-box-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: auto;
  min-width: 290px;
}
.dashOrder-buttons-wrap {
  margin-top: 20px;
}
.dashOrder-buttons-wrap .themeReguler-btn {
  margin-bottom: 8px;
}
.dashVesl-overview-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.dash-graph-wrap {
  margin-left: 50px;
}
.chartInn-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
}
.dash-chartInfo-table {
  background: #ebebeb;
  padding: 20px;
  margin-top: 20px;
}
.dash-chartInfo-table .table-responsive {
  max-height: 75px !important;
  overflow: auto;
}
.dash-chartInfo-table table {
  max-height: 110px;
}
.dash-chartInfo-table h6 {
  color: #000;
  margin-bottom: 20px;
}
.figureTitle {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}
table.table-light2 tr td {
  font-size: 12px;
  color: #000;
  border: none;
  padding: 4px 12px;
}
table.table-light2 tr td:first-child {
  padding-left: 0;
}
table.table-light1 {
  background: #ebebeb;
  width: 100%;
}
table.table-light1 tbody tr {
  width: 100%;
  border-bottom: 5px solid #d3d3d3;
}
table.table-light1 tbody tr:last-child {
  border-bottom: none;
}
table.table-light1 tbody tr td {
  font-size: 14px;
  border: none;
  padding: 4px 12px;
  font-weight: 500;
  color: #4a4a4a;
  vertical-align: middle;
}
.newDash-contentStyle .dash-ord-content.ordContent-table {
  max-height: 250px;
  overflow: auto;
}
.status-wrap-head.content-box-head h2 {
  margin-right: 15px;
}
/* Contract Office Dash Style */
.dashNew-ord-wrap .content-box-wrap.contBx-2 {
  width: 430px;
  padding: 0;
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.custom-select-1 {
  margin: 0 15px;
  height: 25px;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 12px;
  padding: 4px 12px;
  font-weight: 600;
  color: #6f7276;
  max-width: 200px;
}
.form-control.custom-select-1 option {
  background: #c4c4c4;
  font-weight: normal;
  font-size: 10px;
  line-height: 25px;
}
.content-bx-body2 {
  background: #979797;
  border: 2px solid #4a4a4a;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px 20px;
  margin-top: 5px;
}
.dashOrder-buttons-wrap .table.table-transparent th:nth-child(2),
.dashOrder-buttons-wrap .table.table-transparent td:nth-child(2) {
  text-align: center;
}
.dashOrder-buttons-wrap .table.table-transparent th:last-child,
.dashOrder-buttons-wrap .table.table-transparent td:last-child {
  text-align: right;
  font-weight: 500;
}
.dashOrder-buttons-wrap .table.table-transparent tbody td,
.dashOrder-buttons-wrap .table.table-transparent thead th {
  padding: 9px 6px;
}
.table-fz-12 tbody td,
.table-fz-12 thead th {
  font-size: 12px;
}
.table-border-none tbody td,
.table-fz-12 thead th {
  border: none !important;
}
.autoHeight-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 16px) !important;
  height: calc(100% - 16px);
  max-height: 100%;
  overflow: auto;
  margin: 8px;
}
.min-h200 {
  min-height: 200px;
}
.mx-h200 {
  max-height: 200px;
}
.max-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.contract-dash-wrap
  .dash-chartInfo-table
  .custom-w-table
  thead
  th:nth-of-type(n + 3),
.contract-dash-wrap
  .dash-chartInfo-table
  .custom-w-table
  thead
  th:nth-of-type(1),
.contract-dash-wrap
  .dash-chartInfo-table
  .custom-w-table
  tbody
  td:nth-of-type(n + 3),
.contract-dash-wrap
  .dash-chartInfo-table
  .custom-w-table
  tbody
  td:nth-of-type(1) {
  width: 1%;
  white-space: nowrap;
  text-align: center;
}
.custom-w-table thead th:nth-of-type(n + 2),
.custom-w-table tbody td:nth-of-type(n + 2) {
  width: 9%;
  white-space: nowrap;
}
.custom-w-table.text-center-tb thead th:nth-of-type(n + 2),
.custom-w-table.text-center-tb tbody td:nth-of-type(n + 2) {
  text-align: center;
}
.contract-dash-wrap .dash-chartInfo-table .table-light2 thead th {
  padding: 6px 12px;
}
.contract-dash-wrap .dash-chartInfo-table .table-light2 tbody td {
  padding: 3px 12px;
}
.contract-dash-wrap .dash-chartInfo-table .table-responsive {
  max-height: 125px !important;
}
.mx-h125 {
  max-height: 125px !important;
}
.contract-dash-wrap .dash-chartInfo-table {
  padding: 10px 15px;
  margin-top: 0;
  margin-bottom: 10px;
  border: 1px solid #000;
}
.contract-dash-wrap .dash-chartInfo-table.contr-figure-info {
  min-height: 220px;
}
.contract-dash-wrap .dash-chartInfo-table.contr-figure-info.h-auto-adj {
  min-height: 220px;
  height: 462px;
}
.contract-dash-wrap
  .dash-chartInfo-table.contr-figure-info.h-auto-adj
  .table-in-dash {
  max-height: calc(100% - 80px);
}
.contract-dash-wrap
  .dash-chartInfo-table.contr-figure-info.h-auto-adj
  .table-in-dash
  table {
  max-height: 100%;
}
.contract-dash-wrap .dash-chartInfo-table.contr-figure-info .figureTitle {
  font-size: 14px;
}
.date-range-picker span {
  background: #fff;
  border: 3px solid #979797;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 10px;
  position: relative;
}
.contract-dash-wrap .react-select__option {
  padding: 3px 12px;
}
.contract-dash-wrap .custom-select-menu .react-select__control {
  padding: 0 7px;
}
.contract-dash-wrap .custom-select2.text-bx-wrap {
  font-size: 13px;
  font-weight: 400;
}
.contract-dash-wrap .custom-select-menu .react-select__value-container {
  font-weight: 500;
}
.contract-dash-wrap
  .content-box-head
  .datePicker.form-control.field-bx-sty
  input {
  padding: 0 10px;
  font-size: 12px;
}
.contract-dash-wrap .content-box-head .field-bx-sty.datePicker {
  min-height: auto;
  height: 26px;
}
.contract-dash-wrap
  .content-box-head
  .datePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container:after {
  margin-right: 4px;
  border-width: 5px;
  border-bottom-width: 2px;
  border-top-color: rgb(70, 69, 69);
}
.contract-dash-wrap
  .custom-select-menu.custom-select2
  .react-select__indicator.react-select__dropdown-indicator {
  border-width: 5px;
  margin-top: 6px;
}
/* .date-range-picker span::after{display:inline-block;margin-right:7px;vertical-align:2px;content:"";border:5px solid transparent;border-top-color:#6F7276;border-bottom:none} */
.content-box-head.head-transparent {
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}
.fleet-overview-wrap .dashNew-ord-wrap {
  width: 100%;
}
.fleet-overview-wrap .dashNew-ord-wrap .content-box-wrap.contBx-2 {
  width: 100%;
  min-width: 100%;
}
.date-range-picker span input {
  padding: 3px 10px;
  font-weight: 600;
  font-size: 12px;
  color: #6f7276;
  cursor: pointer;
  width: 100%;
  padding-right: 20px;
}
.date-range-picker span .react-datepicker__input-container {
  width: 100%;
  max-width: 100%;
}
.date-range-picker span .react-datepicker-wrapper::after {
  display: inline-block;
  margin-right: 7px;
  vertical-align: 2px;
  content: "";
  border: 5px solid transparent;
  border-top-color: #6f7276;
  border-bottom: none;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
/* Supplier Dashboard */
.supplierDash-ctn-wrap .dashNew-ord-wrap .content-box-wrap.contBx-2 {
  width: 500px;
}
.sm-date-picker.date-range-picker span {
  position: relative;
}
.sm-date-picker.date-range-picker span {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 140px;
  padding-right: 20px;
  cursor: pointer;
}
.sm-date-picker.date-range-picker span:first-child::after {
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.supplierDash-ctn-wrap .dashNew-ord-wrap .content-box-wrap {
  min-width: 100%;
}
.supplierDash-ctn-wrap .dashVesl-overview-wrap {
  width: calc(100% - 550px);
}
.vesselOverview-cont-wrap .dash-chartInfo-table .table-responsive {
  max-height: unset !important;
}
.mv-contai-img-wrap {
  margin-top: 10px;
}
.mv-contai-img-wrap {
  position: relative;
  height: 204px;
}
.mv-contai-img-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-position: center;
  object-position: center;
}
.your-logo-wrap {
  margin-right: 10px;
  width: 65px;
  height: 59px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  background: #ebebeb;
  border: 1px solid #000;
  border-radius: 10px;
}
.your-logo-wrap label {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}
.your-logo-wrap label:before {
  content: "\f303";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  font-family: "Font Awesome 5 Free";
  font-size: 19px;
  color: #fff;
  font-weight: 900;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.your-logo-wrap label:hover:before {
  opacity: 1;
}
.your-logo-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.contract-dash-wrap .dashNew-ord-wrap .content-box-wrap {
  width: 100%;
}
.contract-dash-wrap.cont-ves-wrap .dashNew-ord-wrap .content-box-wrap {
  min-width: 200px;
}
.contract-dash-wrap.cont-ves-wrap
  .dashNew-ord-wrap
  .content-box-wrap.customer-logo-wrap {
  height: 286px;
}
.cust-over-wrap .content-bx-body2 {
  background: #ebebeb;
  border: 1px solid #4a4a4a;
}
.cust-over-wrap .dashOrder-buttons-wrap .table.table-transparent th {
  font-weight: 600;
}
.cust-over-wrap
  .content-box-head
  .datePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container:after {
  border-bottom-width: 0;
  border-top-color: #6f7276;
}
.small-btns .themeReguler-btn {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  font-size: 13px;
  padding: 7px 15px 6px;
  margin-right: 7px;
  border-radius: 4px;
}
.small-btns .themeReguler-btn:last-child {
  margin-right: 0;
}
.h-60v {
  height: 60vh;
  max-height: 60vh;
}
.h-70v {
  height: 70vh;
  max-height: 70vh;
}
.h-75v {
  height: 75vh;
  max-height: 75vh;
}
.h-80v {
  height: 80vh;
  max-height: 80vh;
}
.contract-office-ctn .overWrap-ctrl {
  width: 385px;
  max-width: 385px;
}
.vesselOverview-cont-wrap .overWrap-ctrl {
  width: 300px;
  max-width: 300px;
}
/* .supplierDash-ctn-wrap .contract-dash-wrap .custom-select2.text-bx-wrap {border-radius: 6px;} */
.supplierDash-ctn-wrap .contract-dash-wrap .custom-select2.text-bx-wrap,
.supplierDash-ctn-wrap
  .contract-dash-wrap
  .content-box-head
  .field-bx-sty.datePicker {
  max-width: 150px;
  min-height: 25px;
  border-radius: 4px;
  border: none;
  height: 25px;
}
.supplierDash-ctn-wrap
  .contract-dash-wrap
  .content-box-head
  .field-bx-sty.datePicker
  .react-datepicker__input-container
  input {
  background: transparent !important;
  padding-right: 22px;
}
.supplierDash-ctn-wrap
  .contract-dash-wrap
  .content-box-head
  .field-bx-sty.datePicker
  .react-datepicker__input-container {
  line-height: normal !important;
}
.supplierDash-ctn-wrap
  .contract-dash-wrap
  .custom-select2.text-bx-wrap
  .dropdown-toggle {
  padding-left: 10px;
}
.supplierDash-ctn-wrap
  .contract-dash-wrap
  .custom-select2.text-bx-wrap
  .dropdown-toggle::after {
  right: 7px;
}
.supplierDash-ctn-wrap
  .contract-dash-wrap
  .content-box-head
  .datePicker
  .react-datepicker-wrapper
  .react-datepicker__input-container:after {
  border-bottom-width: 5px;
  border-top-color: #6f7276;
  position: absolute;
  top: 15px;
  right: 7px;
  margin-right: 0 !important;
}
.dash-rfq-status-info .col-md-6:first-child {
  padding-right: 8px;
}
.dash-rfq-status-info .col-md-6:last-child {
  padding-left: 8px;
}
.dash-content-bx-columns .col-md-6:first-child {
  padding-right: 7px;
}
.dash-content-bx-columns .col-md-6:last-child {
  padding-left: 7px;
}

/* Responsive Queries */
@media only screen and (max-width: 1600px) {
  .dash-graph-wrap {
    margin-left: 20px;
  }
  .dash-logo-wrap img {
    max-height: 70px;
  }
  .dash-page-content {
    width: calc(100% - 190px);
  }
  .content-box-head h2,
  .btn.btn-link {
    font-size: 12px;
  }
  .dash-map-content-wrap h2 {
    font-size: 13px;
    margin-top: 20px;
  }
  .btn-sty-title {
    font-size: 28px;
  }
  .your-logo-wrap {
    height: 49px;
  }
  .your-logo-wrap label:before {
    font-size: 16px;
  }
  .contract-dash-wrap.cont-ves-wrap
    .dashNew-ord-wrap
    .content-box-wrap.customer-logo-wrap {
    height: 222px;
    max-height: 222px;
  }
  .mv-contai-img-wrap {
    height: auto;
  }
  .supplierDash-ctn-wrap
    .dashNew-ord-wrap
    .content-box-wrap.contBx-2.cust-over-wrap {
    width: 350px;
  }
  .contract-office-ctn .overWrap-ctrl {
    width: 335px;
    max-width: 335px;
  }
  .vesselOverview-cont-wrap .overWrap-ctrl {
    width: 245px;
    max-width: 245px;
  }
  .supplierDash-ctn-wrap .contract-dash-wrap .custom-select2.text-bx-wrap,
  .supplierDash-ctn-wrap
    .contract-dash-wrap
    .content-box-head
    .field-bx-sty.datePicker {
    max-width: 130px;
    min-height: 23px;
    height: 23px;
  }
  .supplierDash-ctn-wrap
    .contract-dash-wrap
    .custom-select2.text-bx-wrap
    .dropdown-toggle {
    padding-left: 5px;
    font-size: 11px;
    padding-bottom: 0;
    padding-top: 3px;
    font-weight: 500;
  }
  .supplierDash-ctn-wrap
    .contract-dash-wrap
    .custom-select2.text-bx-wrap
    .dropdown-toggle::after {
    right: 6px;
    border-width: 4.5px;
    top: 9px;
  }
  .supplierDash-ctn-wrap
    .contract-dash-wrap
    .content-box-head
    .field-bx-sty.datePicker
    .react-datepicker__input-container
    input {
    font-size: 11px;
    font-weight: 500;
    height: 24px;
    padding-left: 6px;
  }
  .contract-dash-wrap .dash-chartInfo-table.contr-figure-info.h-auto-adj {
    height: 352px;
  }
}
@media only screen and (max-width: 1440px) {
  .contract-dash-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .contract-dash-wrap .dashNew-ord-wrap {
    margin-bottom: 10px;
    margin-right: 0 !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .contract-dash-wrap.cont-ves-wrap .dashNew-ord-wrap > .content-box-wrap {
    margin-right: 10px;
    max-width: 33.33%;
    margin-bottom: 0 !important;
  }
  .contract-dash-wrap .dashNew-ord-wrap .ord-vessl-wrap {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    width: 100%;
  }
  .contract-dash-wrap
    .dashNew-ord-wrap
    .ord-vessl-wrap
    .content-box-wrap:first-child {
    margin-right: 10px;
    margin-bottom: 0 !important;
  }
  .contract-dash-wrap
    .dashNew-ord-wrap
    .ord-vessl-wrap
    .content-box-wrap:last-child,
  .contract-office-ctn .dashNew-ord-wrap .content-box-wrap {
    height: auto;
    max-height: unset;
  }
  .dashNew-ord-wrap .content-box-wrap.contBx-2 {
    margin-right: 20px;
  }
  .supplierDash-ctn-wrap .contract-dash-wrap .dashNew-ord-wrap {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .supplierDash-ctn-wrap .dashVesl-overview-wrap {
    width: 100%;
  }
  .supplierDash-ctn-wrap .contract-dash-wrap {
    margin-top: 10px !important;
  }
  .contrVessel-Dash-wrap .contract-dash-wrap .dashNew-ord-wrap {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .contrVessel-Dash-wrap .ord-vessl-wrap {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    width: 100%;
  }
  .contrVessel-Dash-wrap .ord-vessl-wrap .content-box-wrap:first-child {
    margin-right: 10px;
    margin-bottom: 0 !important;
  }
  .contract-office-ctn .contract-dash-wrap .dashNew-ord-wrap {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .contract-office-ctn .dashNew-ord-wrap .content-box-wrap.contBx-2 {
    margin-right: 15px;
    margin-bottom: 0 !important;
  }
  .contract-dash-wrap.cont-ves-wrap
    .dashNew-ord-wrap
    .content-box-wrap.customer-logo-wrap {
    height: auto;
    max-height: initial;
  }
  .contract-office-ctn .overWrap-ctrl,
  .vesselOverview-cont-wrap .overWrap-ctrl {
    width: 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 1280px) {
  .custom-select-menu .react-select__control {
    padding: 0 10px;
  }
  .btn-order-sty {
    font-size: 13px;
    padding: 13px 10px 10px;
    line-height: normal;
  }
  .dash-pg-inn-wrap {
    padding: 20px 15px 24px;
  }
  .dash-page-content {
    width: calc(100% - 180px);
  }
}
@media only screen and (max-width: 1200px) {
  .vessel-overview-wrap.d-flex.align-items-start {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .vessel-overview-wrap .content-box-head {
    margin-bottom: 20px;
    width: 100% !important;
  }
  .dash-graph-wrap {
    margin-left: 0;
    width: 100%;
  }
  .your-logo-wrap {
    height: 46px;
  }
}
@media only screen and (max-width: 1025px) {
  .dash-page-content {
    width: 100%;
  }
  .btn.btn-link.sidebar-toggle {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .your-logo-wrap {
    height: 42px;
    width: 50px;
  }
  .your-logo-wrap label:before {
    font-size: 14px;
  }
}
@media only screen and (max-width: 992px) {
  .content-box-wrap {
    height: auto;
    margin-top: 20px;
  }
  .contract-office-ctn .content-box-wrap {
    margin-top: 15px;
  }
  .contract-office-ctn .contract-dash-wrap .dashNew-ord-wrap {
    margin-bottom: 0 !important;
  }
  .contrVessel-Dash-wrap .ord-vessl-wrap .content-box-wrap {
    margin-top: 10px;
  }
  .contrVessel-Dash-wrap .contract-dash-wrap .dashNew-ord-wrap {
    margin-bottom: 0 !important;
  }
  .cont-ves-wrap .dashNew-ord-wrap {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .meshBoard-main-wrap .content-box-wrap {
    margin-top: 0;
  }
  .contract-dash-wrap .dashNew-ord-wrap {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    margin-bottom: 0;
  }
  .dashNew-ord-wrap .content-box-wrap.contBx-2 {
    margin-right: 0;
    margin-bottom: 0 !important;
    width: 100%;
    margin-top: 20px !important;
  }
  .contract-dash-wrap .dashNew-ord-wrap .content-box-wrap {
    min-width: 100%;
    margin-top: 20px;
  }
  .contrVessel-Dash-wrap .ord-vessl-wrap {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .contrVessel-Dash-wrap
    .contract-dash-wrap
    .dashNew-ord-wrap
    .content-box-wrap {
    margin-bottom: 0 !important;
  }
  .contract-dash-wrap .dashNew-ord-wrap .ord-vessl-wrap,
  .contract-office-ctn .contract-dash-wrap .dashNew-ord-wrap {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .contract-office-ctn .dashNew-ord-wrap .content-box-wrap.contBx-2 {
    margin-right: 0;
  }
  .contract-dash-wrap .dashNew-ord-wrap > .content-box-wrap {
    margin-right: 0;
    max-width: 100%;
  }
  .mv-contai-img-wrap {
    padding-bottom: 16.25%;
  }
  .cont-ves-wrap .dashNew-ord-wrap {
    margin-right: 0 !important;
    width: 100% !important;
  }
  .contract-dash-wrap.cont-ves-wrap
    .dashNew-ord-wrap
    .content-box-wrap.customer-logo-wrap {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
  }
  .contract-dash-wrap.cont-ves-wrap
    .dashNew-ord-wrap
    .content-box-wrap.customer-logo-wrap {
    height: 180px;
    max-height: 180px;
  }
  .supplierDash-ctn-wrap
    .dashNew-ord-wrap
    .content-box-wrap.contBx-2.cust-over-wrap {
    margin-top: 10px !important;
  }
  .dash-rfq-status-info .col-md-6:first-child {
    padding-right: 15px;
  }
  .dash-rfq-status-info .col-md-6:last-child {
    padding-left: 15px;
  }
  .dash-content-bx-columns .col-md-6:first-child {
    padding-right: 15px;
  }
  .dash-content-bx-columns .col-md-6:last-child {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 576px) {
  .mesh-board-wrap.d-flex.align-items-end {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dash-page-content .mesh-board-wrap .btn-sty-title,
  .dash-head-logo-wrap {
    margin-right: 0;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 100%;
    margin-top: 10px;
  }
  .content-box-head h2,
  .btn.btn-link {
    font-size: 12px;
  }
  .sta-indicator {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
  .text-bx-wrap,
  .custom-select-menu .react-select__placeholder,
  .custom-select-menu .react-select__single-value {
    font-size: 14px;
    font-weight: 500;
  }
  .custom-select-menu
    .react-select__indicator.react-select__dropdown-indicator {
    border-width: 6px;
  }
  .content-box-head h2.font-weight-bold {
    font-weight: 500 !important;
  }
  .dash-new-ord-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .custom-select-menu.text-bx-wrap,
  .dash-ord-opt-wrap {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .dash-ord-opt-wrap:nth-last-of-type(2) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .dash-ord-opt-wrap:nth-of-type(1) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-bottom: 0;
  }
  .content-box-head .btn.btn-link img {
    width: 6px;
  }
  .dash-page-content {
    padding: 20px 10px;
  }
  .content-box-head.ord-status-head h2 {
    max-width: 100px;
  }
  .sta-text-wrap.text-truncate {
    max-width: 35px;
  }
  .content-box-head {
    padding: 3px 10px;
  }
  /* .dash-menu-content .dash-menu-listing .dash-menu-item:nth-last-of-type(2) {margin-top: 50px;} */
  .mesh-board-wrap .content-box-wrap {
    padding: 10px 10px 20px;
  }
  .mesh-boardPort-list {
    margin-top: 20px;
  }
  .date-range-picker span {
    margin: 0 8px;
  }
  .supplierDash-ctn-wrap
    .dashNew-ord-wrap
    .content-box-wrap.contBx-2
    .content-box-head {
    padding: 3px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .supplierDash-ctn-wrap .dashNew-ord-wrap .content-box-wrap.contBx-2 {
    width: 100%;
  }
  .supplierDash-ctn-wrap .vessel-overview-wrap .content-box-head {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: start !important;
  }
  .dash-head-logo-wrap.align-items-start {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .contract-office-ctn .custom-select-menu.text-bx-wrap {
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
    margin-bottom: 0;
    margin: 0 8px !important;
  }
  .supplierDash-ctn-wrap .contract-office-ctn .custom-select-menu.text-bx-wrap {
    margin-left: 0 !important;
  }
  .contract-office-ctn
    .custom-select-menu.custom-select2
    .react-select__placeholder,
  .custom-select-menu .react-select__single-value {
    font-size: 12px;
  }
  .supplierDash-ctn-wrap .custom-select-menu.text-bx-wrap {
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
    margin-bottom: 0;
  }
}
@media (max-width: 400px) {
  .supplierDash-ctn-wrap .vessel-overview-wrap .date-range-picker {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  .supplierDash-ctn-wrap .vessel-overview-wrap .date-range-picker span {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
